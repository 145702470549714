import constants from '../../constants'
import React, { Component } from 'react'
import withAuth from '../../components/withAuth'
import Breadcrumb from '../../components/Breadcrumb'

class GenerateLinkEmail extends Component {

    constructor(props) {
        super(props)

        this.state = {
            link: ""
        }
    }

    generateLink = (e) => {
        const buff = Buffer.from(e.target.value, 'utf-8')
        const base64 = buff.toString('base64')
        const link =  `${constants.url}confirm/${base64}`
        this.setState({link})
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Geração do Link de Confirmação de e-mail</h4>
                                </div>
                                <div className="basic-form">
                                    <form className="form-custom" onSubmit={this.onSave}>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label>E-mail</label>
                                                <input type="text" className="form-control form-control-sm" placeholder="E-mail" onChange={this.generateLink} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label>Link</label>
                                                <input type="text" readOnly={true} value={this.state.link} className="form-control form-control-sm" placeholder="Link" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuth(GenerateLinkEmail)