import axios from 'axios'
import React, { Component } from 'react'
import fileDownload  from 'js-file-download'
import toCSV from 'to-csv'
import withAuth from '../../components/withAuth'
import Breadcrumb from '../../components/Breadcrumb'
import { ElasticInference } from 'aws-sdk';

class ViewsReport extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            area: "",
            slug: "",
            areas: [],
            slugs: [],
            views: null,
            totalViews: 0,
            noRegistration: 0,
            totalRegistration: 0,
            age0_12: 0, age13_17: 0, age18_24: 0, age25_34: 0, age35_44: 0, age45_54: 0, age55_64: 0, age65: 0,
            male: 0, female: 0, others: 0,
            locales: {}
        }
    }

    componentDidMount() {
        this.getArea()
    }

    getPercentage(total, value) {
        return ((100 * value) / total).toFixed(2) + "%"
    }

    getArea() {
        var slugEvent = localStorage.getItem("bigjogos.adm.event.slug")
        let url = `/api/v1/${slugEvent}/views/report/areas`
        axios.get(url, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            const areas = res.data
            areas.unshift("")
            this.setState({areas, views: null})
        }).catch( (error) => {
            if (error.response.status == 401) {
                localStorage.removeItem("token");
                window.open('/', "_self");
            }
        });
    }

    getSlugs(area) {
        var slugEvent = localStorage.getItem("bigjogos.adm.event.slug")
        let url = `/api/v1/${slugEvent}/views/report/area/${area}`
        axios.get(url, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            const slugs = res.data
            slugs.unshift("")
            this.setState({area, slugs})
        }).catch( (error) => {
            if (error.response.status == 401) {
                localStorage.removeItem("token");
                window.open('/', "_self");
            }
        });
    }

    getViews(slug) {
        var slugEvent = localStorage.getItem("bigjogos.adm.event.slug")
        const area = this.state.area
        let url = `/api/v1/${slugEvent}/views/report/area/${area}/${slug}`
        axios.get(url, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            const views = res.data
            let totalViews = 0, age0_12 = 0, age13_17 = 0, age18_24 = 0, age25_34 = 0
            let age35_44 = 0, age45_54 = 0, age55_64 = 0, age65 = 0, noRegistration = 0
            let male = 0, female = 0, others = 0
            let locales = {}

            views.forEach(view => {
                let nView = parseInt(view.views)
                totalViews += nView

                if (view.age == null) {
                    noRegistration += nView
                }
                else {
                    if(parseInt(view.age) <= 12) age0_12 += nView
                    else if(parseInt(view.age) >= 13 && parseInt(view.age) <= 17) age13_17 += nView
                    else if(parseInt(view.age) >= 18 && parseInt(view.age) <= 24) age18_24 += nView
                    else if(parseInt(view.age) >= 25 && parseInt(view.age) <= 34) age25_34 += nView
                    else if(parseInt(view.age) >= 35 && parseInt(view.age) <= 44) age35_44 += nView
                    else if(parseInt(view.age) >= 45 && parseInt(view.age) <= 54) age45_54 += nView
                    else if(parseInt(view.age) >= 55 && parseInt(view.age) <= 64) age55_64 += nView
                    else if(parseInt(view.age) >= 65) age65 += nView

                    if (parseInt(view.genre) == 0) male += nView
                    else if (parseInt(view.genre) == 1) female += nView
                    else if (parseInt(view.genre) == 2) others += nView

                    if (view.language in locales)
                        locales[view.language] += nView
                    else
                        locales[view.language] = nView
                }
            });

            const totalRegistration = totalViews - noRegistration

            this.setState({slug, views, totalViews, noRegistration, totalRegistration,
                age0_12, age13_17, age18_24, age25_34, age35_44, age45_54, age55_64, age65, 
                male, female, others, locales
            })
        }).catch( (error) => {
            if (error.response.status == 401) {
                localStorage.removeItem("token");
                window.open('/', "_self");
            }
        });
    }

    onAreaSelected = (e) => {
        if (e.target.value)
            this.getSlugs(e.target.value)
    }

    onSlugSelected = (e) => {
        if (e.target.value)
            this.getViews(e.target.value)
    }

    onSaveReport = (e) => {
        e.preventDefault()

        fileDownload(toCSV(this.state.views),  `${this.state.area}_${this.state.slug}.csv`)
    }

    renderAreasOptions() {
        return this.state.areas.map(item => {
            
            return (
                <option key={(`select_area_${item}`)} value={item}>{item}</option>
            );
        });
    }

    renderSlugsOptions() {
        return this.state.slugs.map(item => {
            
            return (
                <option key={(`select_slug_${item}`)} value={item}>{item}</option>
            );
        });
    }

    renderLocales() {
        if (this.state.locales) {
            const locales = this.state.locales
            
            return Object.keys(locales).map((key) => {
                return (
                    <div className="form-group col-12 col-md-4">
                        <p><b>{key}:</b> {this.getPercentage(this.state.totalRegistration, locales[key])}</p>
                    </div>
                )
            })
        }

        return ""
    }

    renderViews() {
        if (this.state.views == null)
            return ""

        return (
            <div className="form-row">
                <div className="form-group col-12">
                    <p><b>Views:</b> {this.state.totalViews}</p>
                </div>
                <div className="form-group col-12">
                    <p><b>Sem registro:</b> {this.state.noRegistration}</p>
                </div>

                <div className="form-group col-12"><hr/></div>

                <div className="form-group col-12 col-md-4">
                    <p><b>Até 12 anos:</b> {this.getPercentage(this.state.totalRegistration, this.state.age0_12)}</p>
                </div>
                <div className="form-group col-12 col-md-4">
                    <p><b>De 13 até 17 anos:</b> {this.getPercentage(this.state.totalRegistration, this.state.age13_17)}</p>
                </div>
                <div className="form-group col-12 col-md-4">
                    <p><b>De 18 até 24 anos:</b> {this.getPercentage(this.state.totalRegistration, this.state.age18_24)}</p>
                </div>
                <div className="form-group col-12 col-md-4">
                    <p><b>De 25 até 34 anos:</b> {this.getPercentage(this.state.totalRegistration, this.state.age25_34)}</p>
                </div>
                <div className="form-group col-12 col-md-4">
                    <p><b>De 35 até 44 anos:</b> {this.getPercentage(this.state.totalRegistration, this.state.age35_44)}</p>
                </div>
                <div className="form-group col-12 col-md-4">
                    <p><b>De 45 até 54 anos:</b> {this.getPercentage(this.state.totalRegistration, this.state.age45_54)}</p>
                </div>
                <div className="form-group col-12 col-md-4">
                    <p><b>De 55 até 64 anos:</b> {this.getPercentage(this.state.totalRegistration, this.state.age55_64)}</p>
                </div>
                <div className="form-group col-12 col-md-4">
                    <p><b>Acima de 65 anos:</b> {this.getPercentage(this.state.totalRegistration, this.state.age65)}</p>
                </div>
                <div className="form-group col-12"><hr/></div>
                <div className="form-group col-12 col-md-4">
                    <p><b>Masculino:</b> {this.getPercentage(this.state.totalRegistration, this.state.male)}</p>
                </div>
                <div className="form-group col-12 col-md-4">
                    <p><b>Feminino:</b> {this.getPercentage(this.state.totalRegistration, this.state.female)}</p>
                </div>
                <div className="form-group col-12 col-md-4">
                    <p><b>Outros:</b> {this.getPercentage(this.state.totalRegistration, this.state.others)}</p>
                </div>
                <div className="form-group col-12"><hr/></div>
                {this.renderLocales()}
                <div className="form-group col-12"><hr/></div>
                <div className="form-group col-12 col-md-4">
                    <button className="btn btn-dark" onClick={this.onSaveReport}>Salvar Relatório</button>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Relatório de Views</h4>
                                </div>
                                <div className="basic-form">
                                    <form className="form-custom">
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label>Área</label>
                                                <select name="area" className="form-control form-control-sm" onChange={this.onAreaSelected} >
                                                    {this.renderAreasOptions()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label>Slug</label>
                                                <select name="slug" className="form-control form-control-sm" onChange={this.onSlugSelected} >
                                                    {this.renderSlugsOptions()}
                                                </select>
                                            </div>
                                        </div>
                                        {this.renderViews()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withAuth(ViewsReport)