import React from 'react';
import withAuth from '../../../components/withAuth';
import DataPageEdit from '../../../components/DataPageEdit';
import Breadcrumb from '../../../components/Breadcrumb';

class CategoryGames extends DataPageEdit {
    
    constructor(props) {
        super(props);
        this.slugApi = "category"
    }

    render() {
        return (
        <div>
            <Breadcrumb />
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="content-title">
                                <h4 className="card-title">Categoria</h4>
                                {this.renderLanguage()}
                            </div>
                            <div className="basic-form">
                                <form className="form-custom" onSubmit={this.onSave}>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <label>Nome</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Nome da Categoria" name="title" value={this.getFieldValue("title")} onChange={this.onChangeField} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <button type="submit" className="btn btn-dark">Salvar em todos idiomas</button>
                                        </div>
                                    </div>
                                    {this.renderMessage()}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default withAuth(CategoryGames);