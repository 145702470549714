import axios from 'axios';
import React, { Component } from 'react';

import imgPortugues from "../assets/images/brazil.png";
import imgIngles from "../assets/images/usa.png";


class BasePageEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            slug: "",
            language: "pt",
            itens: [],
            alert: null
        }

        var slugEvent = this.getEventSlug()

        this.slugApi = `${slugEvent}/`
        this.slug = this.props.id
    }

    getEventSlug() {
        return localStorage.getItem("bigjogos.adm.event.slug")
    }

    componentDidMount() {
        let slug = this.slug;
        if (slug != null) {
            let url = "/api/v1/" + this.slugApi + "/" + encodeURI(slug);
            axios.get(url, {headers: {
                'x-access-token': localStorage.getItem("token")
            }}).then((res) => {
                let itens = this.onNewData(res.data) ?? []
                console.log(itens)
                this.setState({itens, slug});
            }).catch( (error) => {
                console.log(error)
                if (error?.response?.status == 401) {
                    localStorage.removeItem("token");
                    window.open('/', "_self");
                }
            });
        }
    }

    onNewData(itens) {
        return itens;
    }

    onChangeLanguage = (e, language) => {
        this.setState({language});
        e.preventDefault();
    }

    onChangeField = (e) => {
        let itens = this.changeValueLanguage(e);
        this.setState({itens, alert: null});
    }

    onChangeFieldAllLocale = (e) => {
        let itens = this.changeValueLanguage(e, "pt");
        itens = this.changeValueLanguage(e, "en", itens);

        this.setState({itens, alert: null});
    }

    changeValueLanguage(e, locale = null, itens = null) {
        let value = "";
        let currentItem = null;

        if (itens == null)
            itens = this.state.itens;
        
        if (locale == null)
            locale = this.state.language;

        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        else if (e.target.tagName === "SELECT" && e.target.multiple) {
            value = [];
            for (let option of e.target.selectedOptions) {
                value.push(option.value);
            };
        }
        else {
            value = e.target.value;
        }

        for (let i = 0; i < itens.length; i++) {
            let item = itens[i];

            if (item["locale"] == locale)
                currentItem =  item;
        }

        

        if (currentItem == null) {
            currentItem = { locale, slug: this.state.slug }
            itens.push(currentItem);
        }

        currentItem[e.target.name] = value;

        return itens;
    }

    getFieldValue(key, locale = null) {
        if (locale === null)
            locale = this.state.language

        for (let i = 0; i < this.state.itens?.length ?? 0; i++) {
            let item = this.state.itens[i];

            if (item["locale"] == locale) {
                if (key in item)
                    return item[key];
            }
        }
        
        return "";
    }

    getItemByLanguage(language) {
        let itens = this.state.itens;

        for (let i = 0; i < itens.length; i++) {
            let item = itens[i];

            if (item["locale"] == language)
                return item;
        }

        return null;
    }

    generateSlug() {
        if (this.state.slug == "" && !this.getFieldValue("slug", "pt")) {
            let item = this.getItemByLanguage("pt");

            if (item == null)
                item = this.state.itens[0];

            if (item == null)
                return

            let slug = item.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, '').replace(/ /g,"-").toLowerCase();

            for (let i = 0; i < this.state.itens.length; i++) {
                this.state.itens[i].slug = slug;
            }

            let itens = this.state.itens;
            itens.forEach(item => {
                item.slug = slug;
            })

            this.setState({itens, slug});

            return slug;
        }
        else if (this.state.slug == "" && this.getFieldValue("slug", "pt")) {
            this.setState({slug: this.getFieldValue("slug", "pt")})
        }

        return this.state.slug;
    }

    getDataToSave() {
        return this.state.itens;
    }

    validateData(itens) {
        return null;
    }

    validateAttentionFields(itens) {
        return true
    }

    onSave = (e) => {
        e.preventDefault();
        let slug = this.generateSlug()
        let itens = this.getDataToSave()
        let validateResult = this.validateData(itens)

        if (!validateResult) {
            let url = "/api/v1/" + this.slugApi + "/" + slug;
            axios.post(url, itens, { headers: {
                'x-access-token': localStorage.getItem("token")
            }}).then((res) => {
                if (res.status == 200) {
                    let attention = this.validateAttentionFields(itens)

                    if (attention)
                        this.showMessage()
                    else
                        this.showMessage(true, "Dados salvos com sucesso, mas parece que alguns campos importantes em alguns idiomas não foram preenchidos.")

                    this.state.slug = this.getFieldValue("slug", "pt")
                } else {
                    this.showMessage(false, "Ocorreu um erro na hora de salvar!")
                }
            });
        }
        else {
            this.showMessage(false, validateResult)
        }
    }

    showMessage(success = true, message = "Dados salvos com sucesso!") {
        let alert = {
            success: success,
            message: message
        }

        this.setState({alert});
    }

    renderLanguage() {
        return (
            <div className="idiomas">
                <span>editando em:</span>
                <a href="#" title="Português" onClick={(e) => this.onChangeLanguage(e, "pt")}  className={(this.state.language == "pt"? "active" : "")}><img src={imgPortugues} alt="Português"/></a>
                <a href="#" title="Inglês" onClick={(e) => this.onChangeLanguage(e, "en")} className={(this.state.language == "en"? "active" : "")}><img src={imgIngles} alt="Inglês"/></a>
            </div>
        );
    }

    calculateColSizes = (parameters) => {
        if (!parameters.col_sm)
            parameters.col_sm = parameters.col 

        if (!parameters.col_md)
            parameters.col_md = parameters.col_sm

        if (!parameters.col_lg)
            parameters.col_lg = parameters.col_md

        if (!parameters.col_xl)
            parameters.col_xl = parameters.col_lg
    }

    renderTextInput2 = (parameters = {}) => {
        const defaultValues = {
            name: "", 
            label: "",
            placeholder: "",
            type: "text",
            value: this.getFieldValue(parameters?.name),
            onChange: this.onChangeField,
            col: 12
        }

        parameters = Object.assign(defaultValues, parameters)
        this.calculateColSizes(parameters)

        if (parameters.placeholder == null)
            parameters.placeholder = parameters.label

        return (
            <div className={(`form-group col-${parameters.col} col-sm-${parameters.col_sm} col-md-${parameters.col_md} col-lg-${parameters.col_lg} col-xl-${parameters.col_xl}`)}>
                <label>{parameters.label}</label>
                <input type={parameters.type} className="form-control form-control-sm" placeholder={parameters.placeholder} name={parameters.name} value={parameters.value} onChange={parameters.onChange} />
            </div>
        );
    }

    renderTextInput(name, label, placeholder = null, col = 12, col_sm = col, col_md = col_sm, col_lg = col_md, col_xl= col_lg) {
        return this.renderTextInputComplete(name, label, placeholder, this.getFieldValue(name), this.onChangeField, col, col_sm, col_md, col_lg, col_xl)
    }

    renderTextInputChangeAll(name, label, placeholder = null, col = 12, col_sm = col, col_md = col_sm, col_lg = col_md, col_xl= col_lg) {
        return this.renderTextInputComplete(name, label, placeholder, this.getFieldValue(name), this.onChangeFieldAllLocale, col, col_sm, col_md, col_lg, col_xl)
    }

    renderTextInputComplete(name, label, placeholder, value, onChange, col, col_sm, col_md, col_lg, col_xl) {
        if (placeholder == null)
            placeholder = label;

        return (
            <div className={(`form-group col-${col} col-sm-${col_sm} col-md-${col_md} col-lg-${col_lg} col-xl-${col_xl}`)}>
                <label>{label}</label>
                <input type="text" className="form-control form-control-sm" placeholder={placeholder} name={name} value={value} onChange={onChange} />
            </div>
        );
    }

    renderTextArea(name, label, placeholder = null, col = 12, col_sm = col, col_md = col_sm, col_lg = col_md, col_xl= col_lg) {
        if (placeholder == null)
            placeholder = label;

        return (
            <div className={(`form-group col-${col} col-sm-${col_sm} col-md-${col_md} col-lg-${col_lg} col-xl-${col_xl}`)}>
                <label>{label}</label>
                <textarea className="textarea_editor form-control form-control-sm" placeholder={placeholder} name={name} value={this.getFieldValue(name)} onChange={this.onChangeField} />
            </div>
        );
    }

    renderSelect(name, label, options, col = 12, col_sm = col, col_md = col_sm, col_lg = col_md, col_xl= col_lg) {
        return this.renderSelectComplete(name, label, options, this.getFieldValue(name), this.onChangeField, col, col_sm, col_md, col_lg, col_xl);
    }

    renderSelectChangeAll(name, label, options, col = 12, col_sm = col, col_md = col_sm, col_lg = col_md, col_xl= col_lg) {
        return this.renderSelectComplete(name, label, options, this.getFieldValue(name), this.onChangeFieldAllLocale, col, col_sm, col_md, col_lg, col_xl);
    }

    renderSelectComplete(name, label, options, value, onChange, col, col_sm, col_md, col_lg, col_xl) {
        let optionsLocale = [];

        if (this.state.language in options)
            optionsLocale = options[this.state.language];

        const optionsRender = Object.keys(optionsLocale).map((key) => {
            return (
                <option key={(`select_${name}_${key}`)} value={key}>{optionsLocale[key]}</option>
            );
        });

        return (
            <div className={(`form-group col-${col} col-sm-${col_sm} col-md-${col_md} col-lg-${col_lg} col-xl-${col_xl}`)}>
                <label>{label}</label>
                <select className="form-control form-control-sm" name={name} value={value} onChange={onChange}>
                    {optionsRender}
                </select>
            </div>
        );
    }

    renderMessage() {
        if (this.state.alert == null)
            return null;

        let classAlertType = "success";

        if (!this.state.alert.success)
            classAlertType = "danger";

        return (
            <div className="form-row">
                <div className="form-group col-12">
                    <div className={("alert alert-" + classAlertType)}>{this.state.alert.message}</div>
                </div>
            </div>
        )
    }
}

export default BasePageEdit;