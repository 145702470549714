import React from 'react';
import withAuth from '../../components/withAuth';
import Breadcrumb from '../../components/Breadcrumb';
import BasePageList from '../../components/BasePageList';

class GamesList extends BasePageList {

    constructor(props) {
        super(props);
        this.slugSingularApi += "game"
        this.slugPluralApi += "games"
    }

    renderGames() {
        return this.state.itens.map(item => {
            return (
                <tr key={("categoria_" + item.slug)}>
                    <td>{item.title}</td>
                    <td>{item.area}</td>
                    <td>{item.play && "Sim"}{!item.play && "Não"}</td>
                    <td>{item.status && "Ativo"}{!item.status && "Inativo"}</td>
                    <td>{item.actions?.length ?? 0}</td>
                    <td>{item.powered ?? ""}</td>
                    <td><span>
                        <a href={("/game/" + item.slug)} data-toggle="tooltip" data-placement="top" title="Editar"><i className="fa fa-pencil color-muted m-r-5"></i> </a>
                        <a href="#" onClick={(e) => {this.onDeleteConfirm(e, item.slug, item.title)}} data-toggle="tooltip" data-placement="top" title="Remover"><i className="fa fa-close color-danger"></i></a>
                    </span></td>
                </tr>
            );
        });
    }

    render() {
        return (
        <div>
            <Breadcrumb />
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Jogos</h4>
    
                            <div className="table-responsive">
                                <table className="table verticle-middle table-lista-big">
                                    <thead>
                                        <tr>
                                            <th scope="col">Categoria</th>
                                            <th scope="col">Área</th>
                                            <th scope="col">Link</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Botões</th>
                                            <th scope="col">Plataforma</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderGames()}
                                    </tbody>
                                </table>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    <a href="/game/" className="btn btn-dark" title="Novo Jogo">Novo Jogo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

}

export default withAuth(GamesList);