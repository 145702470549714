import axios from 'axios';
import React, { Component } from 'react';
import { Modal, Tabs, Tab } from "react-bootstrap";

class GallerySelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
          itens: [],
          file: null,
          name: null,
          page: 1,
        }

        this.itensPerPage = 20;
    }

    componentDidMount() {
        let url = "/api/v1/galleries";
        axios.get(url, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            let itens = res.data.reverse();
            console.log(itens);
            this.setState({itens});
        }).catch( (error) => {
            if (error.response.status == 401) {
                localStorage.removeItem("token");
                window.open('/', "_self");
            }
        });
    }

    onSelectImage = (url) => {
        this.props.onImageSelected(url);
    }

    onChangeName = (e) => {
        let name = this.state.name;
        name = e.target.value;

        this.setState({name});
    }

    onChangeFile = (e) => {
        const reader = new FileReader();
        let file = this.state.file;
        let name = this.state.name;
        file = e.target.files[0];
        
        if (name == null)
            name = file.name;

        this.setState({ file, name });
    }

    saveFile = () => {
        let file = this.state.file;
        let name = this.state.name;

        if (file != null && name != null) {
            const data = new FormData();
            const url = "/api/v1/gallery/";

            data.append('file', file);
            data.append('filename', file.name);
            data.append('title', name);

            fetch(url, {
                method: 'POST',
                headers: {
                    'x-access-token': localStorage.getItem("token")
                },
                body: data
            }).then((response) => {
                response.json().then((body) => {
                  this.onSelectImage(body);
                });
            });
        }
    }

    renderImages() {
        return this.state.itens.map((item, index) => {
            if (index < (this.state.page * this.itensPerPage)) {
                return (
                    <div key={("image_" + index)} className="imageGallery" onClick={() => { this.onSelectImage(item.url) }} >
                        <div className="imageContainer">
                            <img src={item.url} />
                        </div>
                        <p>{item.title}</p>
                    </div>
                );
            }
        });
    }

    render() {
        return (
            <Modal
                title="Galeria"
                show={true}
                onHide={this.props.onClose}
                dialogClassName="modalGaleria">
                <h2>Galeria</h2>

                <Tabs defaultActiveKey="images">
                    <Tab eventKey="images" title="Imagens">
                        <>
                            <div className="boxGallery">
                                {this.renderImages()}
                            </div>
                            <input type="button" className="btn" value="Carregar mais" onClick={() => { this.setState({ page: this.state.page + 1 }) }} />
                        </>
                    </Tab>
                    <Tab eventKey="upload" title="Upload">
                        <div className="card-body">
                            <form className="form-custom">
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <label>Selecionar Imagem</label>
                                        <input type="file" accept="image/*" className="form-control form-control-sm" onChange={this.onChangeFile} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <label>Nome da Imagem</label>
                                        <input type="text" placeholder="Nome da imagem" className="form-control form-control-sm" value={this.state.name} onChange={this.onChangeName} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <button type="button" className="btn" onClick={this.saveFile}>Salvar Imagem</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Tab>
                </Tabs>
            </Modal>
        );
    }
}

export default GallerySelector;