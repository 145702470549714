import axios from 'axios';
import React, { Component } from 'react';

class SelectFK extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itens: [],
            slug: ""
        }
    }

    componentDidMount() {
        let slug = this.props.slug;

        if (this.props.eventRequired) {
            var slugEvent = localStorage.getItem("bigjogos.adm.event.slug")
            slug = `${slugEvent}/${slug}`
        }

        if (slug != null) {
            let url = "/api/v1/" + slug + "/options/";
            axios.get(url, {headers: {
                'x-access-token': localStorage.getItem("token")
            }}).then((res) => {
                console.log(res.data);
                let itens = res.data;
                

                itens.sort((a, b) => {
                    if (a.title?.toLowerCase() < b.title?.toLowerCase()) return -1
                    if (a.title?.toLowerCase() > b.title?.toLowerCase()) return 1

                    return 0
                })

                this.setState({itens, slug});
            });
        }
    }

    renderOptions() {

        let itens = this.state.itens.slice();

        if (this.props.emptyElement) {
            itens.unshift ({
                locale: this.props.locale,
                slug: "",
                title: ""
            });
        }

        return itens.map(item => {
            if (item.locale == this.props.locale) {
                return (
                    <option key={(`select_${this.props.name}_${item.locale}_${item.slug}`)} value={item.slug}>{item.title}</option>
                );
            } else {
                return "";
            }
        });
    }

    renderSelectMultiple() {
        return (
            <select name={this.props.name} multiple={true} className="form-control form-control-sm multiple" value={this.props.value} onChange={this.props.onChange}>
                {this.renderOptions()}
            </select>
        );
    }

    renderSelectSingle() {
        return (
            <select name={this.props.name} className="form-control form-control-sm" value={this.props.value} onChange={this.props.onChange}>
                {this.renderOptions()}
            </select>
        );
    }

    render() {
        let selectRender = "";
        let message = "";

        if (this.props.multiple) {
            selectRender = this.renderSelectMultiple();
            message = (<small> (segure o Control para selecionar mais de uma)</small>);
        }
        else {
            selectRender = this.renderSelectSingle();
            message = "";
        }

        const col = this.props.col ?? "12"
        const colsm = this.props.colsm ?? col
        const colmd = this.props.colmd ?? colsm
        const collg = this.props.collg ?? colmd
        const colxl = this.props.colxl ?? collg

        return (
            <div className={(`form-group col-${col} col-sm-${colsm} col-md-${colmd} col-lg-${collg} col-xl-${colxl}`)}>
                <label>{this.props.label}{message}:</label>
                {selectRender}
            </div>
        );
    }
}

export default SelectFK;