import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { DropContainer, UploadMessage } from "./styles";

function Upload({ onUpload, file }) {
  const renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      if (!!file.length) {
        return <UploadMessage>{file[0].name}</UploadMessage>;
      } else {
        return <UploadMessage>Arraste arquivos aqui...</UploadMessage>;
      }
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  };

  return (
    <Dropzone
      accept=".csv, application/vnd.ms-excel, text/csv"
      onDropAccepted={onUpload}
      multiple={false}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <>
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            {renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        </>
      )}
    </Dropzone>
  );
}

export default Upload;
