import React from 'react';
import withAuth from '../../../components/withAuth';
import DataPageEdit from '../../../components/DataPageEdit';
import Breadcrumb from '../../../components/Breadcrumb';
import ImageSelector from "../../../components/ImageSelector";

class PlatformEdit extends DataPageEdit {
    constructor(props) {
        super(props);
        this.slugApi = "platform"
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Plataforma</h4>
                                    {this.renderLanguage()}
                                </div>
                                <div className="basic-form">
                                    <form className="form-custom" onSubmit={this.onSave}>
                                        <div className="form-row">
                                            {this.renderTextInput("title", "Nome", "Nome da plataforma", 12, 12, 8)}
                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                            <div className="checkbox">
                                                <label>Mostrar powered by na Plataforma</label>
                                                <div className="col-12">
                                                    <input className="js-switch_2" name="show" checked={this.getFieldValue("show")} type="checkbox" onChange={this.onChangeFieldAllLocale} />
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="form-row">
                                            <ImageSelector label="Logo da Plataforma" col="12" name="thumb" width="122" height="35" value={this.getFieldValue("thumb")} onChange={this.onChangeField} />
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <button type="submit" className="btn btn-dark">Salvar em todos idiomas</button>
                                            </div>
                                        </div>
                                        {this.renderMessage()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuth(PlatformEdit);