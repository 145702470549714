import React from 'react';
import withAuth from '../../components/withAuth';
import BasePageEdit from '../../components/BasePageEdit';
import Breadcrumb from '../../components/Breadcrumb';

class ConfigEdit extends BasePageEdit {
    constructor(props) {
        super(props)
        this.slugApi = "event"
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Dados do Evento</h4>
                                    {this.renderLanguage()}
                                </div>
                                <div className="datamap">
                                    <div id="world-datamap">
                                        <form onSubmit={this.onSave}>
                                            <div className="form-row m-b-20">
                                                {this.renderTextInput("title", "Título", "Título do Evento", 12)}
                                            </div>

                                            <div className="form-row m-b-20">
                                                {this.renderTextInputChangeAll("slug", "Slug", "Slug do evento, será incluso em todas as URLs", 12, 12, 6)}
                                                {this.renderTextInput("linktalks", "Link Palestras", "Link da palestras", 12, 12, 6)}
                                            </div>

                                            <div className="form-row m-b-20">
                                                {this.renderTextInput("privacy", "Política de Privacidade", "Link da área política de privacidade", 12, 12, 6)}
                                                {this.renderTextInput("terms", "Termos de Uso", "Link o termos de uso", 12, 12, 6)}
                                            </div>

                                            <div className="form-row m-b-20">
                                                <div className="form-group col-12 col-md-6">
                                                    <label>Início do Evento Aberto ao Público</label>
                                                    <input type="date" className="form-control form-control-sm" placeholder="Início do Evento Aberto ao Público" name="startdate" value={this.getFieldValue("startdate")} onChange={this.onChangeFieldAllLocale} />
                                                </div>
                                                <div className="form-group col-12 col-md-6">
                                                    <label>Início do Evento para os VIP</label>
                                                    <input type="date" className="form-control form-control-sm" placeholder="Início do Evento para os VIP" name="startdatevip" value={this.getFieldValue("startdatevip")} onChange={this.onChangeFieldAllLocale} />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-12 col-sm-12 col-md-6">
                                                    <div className="checkbox margin-left-0">
                                                        <label>Tipo de HomePage</label>
                                                        <select className="form-control form-control-sm" name="hometype" value={this.getFieldValue("hometype")} onChange={this.onChangeFieldAllLocale}>
                                                            <option value={0}>Mapa</option>
                                                            <option value={1}>Lista</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-12 col-sm-12 col-md-6">
                                                    <div className="checkbox margin-left-0">
                                                        <label>Habilitar número de visitantes ao vivo em todo o site?</label>
                                                        <div className="col-12">
                                                            <input className="js-switch" name="numbervisitors" checked={this.getFieldValue("numbervisitors")} type="checkbox" onChange={this.onChangeFieldAllLocale} />
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 col-sm-12 col-md-6">
                                                    <div className="checkbox">
                                                        <label>Habilitar número de visualizações em todo o site?</label>
                                                        <div className="col-12">
                                                            <input className="js-switch_2" name="numberviews" checked={this.getFieldValue("numberviews")} type="checkbox" onChange={this.onChangeFieldAllLocale} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-12 col-sm-12 col-md-6">
                                                    <div className="checkbox margin-left-0">
                                                        <label>Habilita votos</label>
                                                        <div className="col-12">
                                                            <input className="js-switch" name="vote" checked={this.getFieldValue("vote")} type="checkbox" onChange={this.onChangeFieldAllLocale} />
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 col-sm-12 col-md-6">
                                                    <div className="checkbox margin-left-0">
                                                        <label>Evento Ativo</label>
                                                        <div className="col-12">
                                                            <input className="js-switch" name="active" checked={this.getFieldValue("active")} type="checkbox" onChange={this.onChangeFieldAllLocale} />
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row m-b-20">
                                                {this.renderTextArea("header_description", "Cabeçalho", "Texto do cabeçalho das páginas", 12)}
                                            </div>
                                            <button type="submit" className="btn btn-dark">Salvar em todos idiomas</button>
                                        </form>
                                        {this.renderMessage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withAuth(ConfigEdit)