import React, { Component } from 'react'
import ImageSelector from "./ImageSelector"

class CommandItemEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    renderOthers() {
        let intCommand = parseInt(this.props.item.command)
        if (intCommand > 0)
            return ""
        
        return (
            <div className="form-group form-row col-12">
                <div className="form-group col-12 col-lg-6">
                    <label>Label:</label>
                    <input type="text" name="label" className="form-control form-control-sm" placeholder="Detalhe do comando" value={this.props.item.label} onChange={(e) => this.props.onChange(e, this.props.index)} />
                </div>
                <ImageSelector label="Imagem do Comando" col="6" name="thumb" width="70" height="50" value={this.props.item.thumb} onChange={(e) => this.props.onChange(e, this.props.index)} />
            </div>
        )
    }

    render() {
        return (
            <div className="form-row col-10">
                <div className="form-group col-12 col-lg-6">
                    <label>Commando {(this.props.index + 1)}</label>
                    <input type="text" name="action" className="form-control form-control-sm" placeholder="Texto do botão" value={this.props.item.action} onChange={(e) => this.props.onChange(e, this.props.index)} />
                </div>
                <div className="form-group col-12 col-lg-6">
                    <label>Controle:</label>
                    <select name="command" className="form-control form-control-sm" value={this.props.item.command} onChange={(e) => this.props.onChange(e, this.props.index)}>
                        <option value="-10">Keyboard</option>
                        <option value="-15">Joypad</option>
                        <option value="-20">Touch</option>
                    </select>
                </div>
                {this.renderOthers()}
            </div>
        )
    }
}

export default CommandItemEdit