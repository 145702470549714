import React from "react";
import withAuth from "../../components/withAuth";
import BaseIdPageEdit from "../../components/BaseIdPageEdit";
import Breadcrumb from "../../components/Breadcrumb";

import SyncVote from "./SyncVote";
import EditItemVote from "./EditItemVote";
import ResultVote from "./ResultVote";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { renderTextInput, renderSelect, renderTextArea } from "../../components/renderControls";

class EditVote extends BaseIdPageEdit {
  constructor(props) {
    super(props);
    this.slugApi += "vote";
    this.state.title = "Votação";
  }

  onNewData(item) {
    item.event_slug = this.getEventSlug();

    if (!Array.isArray(item.itens))
      item.itens = [];

    return item;
  }

  renderPage() {
    return (
      <div>
        <Breadcrumb categoryName="Votação" categoryLink="/votes" title={this.getFieldValue("name")} />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="content-title">
                  <h4 className="card-title">Votação</h4>
                </div>
                <div className="basic-form">
                  <form className="form-custom" onSubmit={this.onSave}>
                    <div className="form-row">
                      {renderTextInput(
                        {
                          name: "name",
                          label: "Nome",
                          col_md: 6,
                          placeholder: "Nome da Votação",
                          value: this.getFieldValue("name"),
                          onChange: this.onChangeField,
                        }
                      )}
                      {renderSelect(
                        {
                          name: "type",
                          label: "Tipo",
                          col_md: 6,
                          placeholder: "Tipo da Votação",
                          options: [
                            { value: "0", label: "Padrão" },
                            { value: "1", label: "Games" },
                          ],
                          value: this.getFieldValue("type"),
                          onChange: this.onChangeField,
                        }
                      )}
                    </div>
                    <div className="form-row">

                    <Tabs
                      defaultActiveKey="layout"
                      id="extra-configs"
                      className="mb-3 w-100">
                      <Tab eventKey="layout" title="Layout" className="m-3">
                        <div className="form-row">
                          {renderTextArea({
                            name: "home_text",
                            label: "Texto da Home",
                            col_md: 12,
                            placeholder: "Texto que aparece na primeira tela do Totem",
                            value: this.getFieldValue("home_text"),
                            onChange: this.onChangeField,
                          })}
                        </div>
                        <div className="form-row">
                          {renderTextArea({
                            name: "choice_text",
                            label: "Texto da Tela de Escolha",
                            col_md: 12,
                            placeholder: "Texto que aparece na primeira tela de escolha dos itens",
                            value: this.getFieldValue("choice_text"),
                            onChange: this.onChangeField,
                          })}
                        </div>
                        <div className="form-row">
                          {renderTextArea({
                            name: "vote_text",
                            label: "Texto da Confirmação do Voto",
                            col_md: 12,
                            placeholder: "Texto que aparece no modal de confirmação do voto",
                            value: this.getFieldValue("vote_text"),
                            onChange: this.onChangeField,
                          })}
                        </div>
                      </Tab>
                      <Tab eventKey="totens" title="Totens Sincronizados" className="m-3">
                        <SyncVote voteId={this.id} />
                      </Tab>
                      {this.getFieldValue("type") == 0 &&
                        <Tab eventKey="itens" title="Itens para Votação" className="m-3">
                          <EditItemVote voteId={this.id}
                                        itens={this.getFieldValue("itens")} 
                                        onChange={this.onChangeField} />
                        </Tab>
                      }
                      <Tab eventKey="result" title="Resultados" className="m-3">
                        <ResultVote voteId={this.id} />
                      </Tab>
                    </Tabs>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-12">
                        <button type="submit" className="btn btn-dark">
                          Salvar
                        </button>
                      </div>
                    </div>
                    {this.renderMessage()}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAuth(EditVote);
