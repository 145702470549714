import React, { Component } from 'react';

class Breadcrumb extends Component {
    constructor(props) {
        super(props);


    }

    render() {
        return (
            <div className="row page-titles">
                <div className="col p-0">
                    <h4>Olá, <span>bem-vindo(a)</span></h4>
                </div>
                <div className="col p-0">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/" title="Dashboard">Dashboard</a></li>
                        {this.props.categoryName != null && 
                            <li className="breadcrumb-item">
                                <a href={this.props.categoryLink} title={this.props.categoryName}>{this.props.categoryName}</a>
                            </li>
                        }
                        <li className="breadcrumb-item active">{this.props.title}</li>
                    </ol>
                </div>
            </div>
        );
    }
}

export default Breadcrumb;