import React from 'react';
import withAuth from '../../components/withAuth';
import DataPageEdit from '../../components/DataPageEdit';
import Breadcrumb from '../../components/Breadcrumb';
import ImageSelector from "../../components/ImageSelector";
import SelectFK from '../../components/SelectFK';

class SponsorEdit extends DataPageEdit {
    constructor(props) {
        super(props);
        this.slugApi += "sponsor"

        this.addAttentionFields([
            'imagehorizontal',
            'imagevertical',
            'url'
        ])
    }

    render() {
        return (
            <div key={`sponsor_${this.state.language}`}>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Propaganda</h4>
                                    {this.renderLanguage()}
                                </div>
                                <div className="basic-form">
                                    <form className="form-custom" onSubmit={this.onSave}>
                                        <div className="form-row">
                                            {this.renderTextInput("title", "Nome", "Nome da propaganda")}
                                        </div>
                                        <div className="form-row">
                                            {this.renderTextInput("url", "URL", "URL de clique")}
                                        </div>
                                        <div className="form-row">
                                            <SelectFK multiple="true" label="Em qual Área do Festival a propaganda será exibida?" eventRequired={true} slug="festivalAreas" locale={this.state.language} name="festival_areas" value={this.getFieldValue("festival_areas")} onChange={this.onChangeFieldAllLocale} col="12" colmd="6" />
                                            <SelectFK multiple="true" label="Em qual categoria a propaganda será exibida? (Exibição na página de BigFestival)" slug="categories" locale={this.state.language} name="categories" value={this.getFieldValue("categories")} onChange={this.onChangeFieldAllLocale} col="12" colmd="6" />
                                        </div>
                                        <div className="form-row">
                                            <ImageSelector label="Imagem da Propaganda (web)" col="12" name="imagevertical" width="180" height="1000" value={this.getFieldValue("imagevertical")} onChange={this.onChangeField} />
                                            <ImageSelector label="Imagem da Propaganda (mobile)" col="12" name="imagehorizontal" width="1000" height="100" value={this.getFieldValue("imagehorizontal")} onChange={this.onChangeField} />
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <div className="checkbox margin-left-0">
                                                    <label>Status</label>
                                                    <div className="col-12">
                                                        <input className="js-switch" name="status" checked={this.getFieldValue("status")} onChange={this.onChangeField} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <button type="submit" className="btn btn-dark">Salvar em todos idiomas</button>
                                            </div>
                                        </div>
                                        {this.renderMessage()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuth(SponsorEdit);