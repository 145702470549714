import React from 'react';
import BasePageEdit from './BasePageEdit';

class DataPageEdit extends BasePageEdit {

    constructor(props) {
        super(props);
        this.requiredFields = ['title']
        this.attentionFields  = []
    }

    addRequiredFields(fields) {
        if (Array.isArray(fields))
            this.requiredFields = this.requiredFields.concat(fields)
        else
            this.requiredFields.push(fields)
    }

    addAttentionFields(fields) {
        if (Array.isArray(fields))
            this.attentionFields = this.attentionFields.concat(fields)
        else
            this.attentionFields.push(fields)
    }

    validateData(itens) {
        if (itens.length < 2)
            return "O campo nome deve ser preenchido em todos os idiomas."

        let message = ""

        this.requiredFields.forEach((field) => {
            itens.forEach((item) => {
                if (!(field in item) || !(item[field]))
                    message += "O campo " + field + " deve ser preenchido em todos os idiomas.\n"
            })
        })
            
        return message
    }

    validateAttentionFields(itens) {
        let ok = true

        itens.forEach(item => {
            this.attentionFields.forEach(att => {
                ok = ok && item[att] != null && item[att] != ""
            })
        })
        return ok
    }
}

export default DataPageEdit;