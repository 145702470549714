import React from 'react';
import withAuth from '../../components/withAuth';
import DataPageEdit from '../../components/DataPageEdit';
import Breadcrumb from '../../components/Breadcrumb';
import ImageSelector from "../../components/ImageSelector";
import PinMap from '../../components/PinMap';
import constants from '../../constants';

class PublishersEdit extends DataPageEdit {
    constructor(props) {
        super(props);
        this.slugApi += "publisher"

        this.addAttentionFields([
            'thumb',
            'map'
        ])
    }

    render() {
        return (
            <div key={`publisher_${this.state.language}`}>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Publisher</h4>
                                    {this.renderLanguage()}
                                </div>
                                <div className="basic-form">
                                    <form className="form-custom" onSubmit={this.onSave}>
                                        <div className="form-row">
                                            {this.renderTextInputChangeAll("slug", `${constants.url}${this.getEventSlug()}/publishers/${this.getFieldValue("slug")}`, "Slug: Deixe vazio para gerar automaticamente", 12, 12, 6)}
                                            <div className="form-group col-12 col-sm-6">
                                                <div className="checkbox">
                                                    <label>Status</label>
                                                    <div className="col-12">
                                                        <input className="js-switch_3" name="status" checked={this.getFieldValue("status")} type="checkbox" onChange={this.onChangeFieldAllLocale} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            {this.renderTextInput("title", "Nome", "Nome da publisher", 12)}
                                        </div>
                                        <div className="form-row">
                                            <ImageSelector label="Logo do Publisher" col="12" name="thumb" width="300" height="300" value={this.getFieldValue("thumb")} onChange={this.onChangeField} />
                                        </div>
                                        <div className="form-row">
                                            <ImageSelector label="Imagem da Publisher" col="12" name="map" width="937" height="568"  value={this.getFieldValue("map")} onChange={this.onChangeField} />
                                        </div>
                                        <PinMap name="pins" size="22" image={this.getFieldValue("map")} locale={this.state.language} label="Publisher" pins={this.getFieldValue("pins")} onChange={this.onChangeField}  />
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <button type="submit" className="btn btn-dark">Salvar em todos idiomas</button>
                                            </div>
                                        </div>
                                        {this.renderMessage()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuth(PublishersEdit);