import React from "react";
import { Redirect } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

const withAuth = (Component) => {
    const AuthRoute = (props) => {
      const isAuth = !!localStorage.getItem("token");
      if (isAuth) {
        return ( 
        <div>
          <Header />
          <Sidebar />
          <div className="content-body">
            <div className="container-fluid">
              <Component {...props} />
            </div>
          </div>
          <Footer />        
        </div>

        );
      } else {
        return <Redirect to="/login/" />;
      }
    };
  
    return AuthRoute;
};

export default withAuth;