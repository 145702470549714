import React, { Component } from 'react'


class PeriodsEdit extends Component {

    constructor(props) {
        super(props)
    }

    addPeriod = () => {
        let periods = this.props.periods

        if (!Array.isArray(periods))
            periods = []

        periods.push({})
        this.onPeriodsChangeValue(periods)
    }

    removePin = (index) => {
        let periods = this.props.periods;
        periods.splice(index, 1);
        this.onPeriodsChangeValue(periods);
    }

    onChangeField = (e, index) => {
        let value = "";
        let periods = this.props.periods;

        if (e.target.type === "checkbox")
            value = e.target.checked;
        else
            value = e.target.value;

        let period = periods[index];
        period[e.target.name] = value;
        periods[index] = period;

        this.onPeriodsChangeValue(periods);
    }

    onPeriodsChangeValue(periods) {
        if (this.props.onChange != undefined) {
            let event = {
                target: {
                    type: "periods",
                    name: this.props.name,
                    value: periods
                }
            };

            this.props.onChange(event);
        }
    }
    

    renderPeriodsToEdit(period, index) {
        let last = "";

        if (index >= (this.props.periods.length - 1))
            last = (<button type="button" className="btn btn-outline-success btn-xs" onClick={this.addPeriod}>adicionar</button>);

        let alert = ""

        if (period.end < period.start) 
            alert = <div className="form-row alert alert-danger">O tempo início do período é maior que o tempo do fim do período</div>

        for (let periodTest of this.props.periods) {
            if (periodTest != period) {
                if ((period.start > periodTest.start
                        && period.start < periodTest.end)
                    || (period.end > periodTest.start
                        && period.end < periodTest.end))

                        alert = <div className="form-row alert alert-danger">Este período está em conflito com outro</div>
            }
        }

        return (
            <div key={(`period_${this.props.name}_${index}`)} className="line-period">
                <div className="form-row">
                    <label>Período {(index+1)}</label>
                </div>
                <div className="form-row">
                    <div className="form-group col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                        <label>Início do Período</label>
                        <input type="datetime-local" name="start" className="form-control form-control-sm" placeholder="Início do período" value={period.start} onChange={(e) => this.onChangeField(e, index)} />
                    </div>
                    <div className="form-group col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                        <label>Fim do Período</label>
                        <input type="datetime-local" name="end" className="form-control form-control-sm" placeholder="Fim do período" value={period.end} onChange={(e) => this.onChangeField(e, index)} />
                    </div>
                    <div className="form-group col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                        <label>Quantidade</label>
                        <input type="number" min="0" name="limit" className="form-control form-control-sm" placeholder="Quantidade de cupons liberados no período" value={period.limit} onChange={(e) => this.onChangeField(e, index)} />
                    </div>
                    <div className="form-group buttons col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                        <button type="button" className="btn btn-outline-primary btn-xs" onClick={() => {this.removePin(index)}}>remover</button>
                        {last}
                    </div>
                </div>
                {alert}
            </div>
        );
    }

    renderEmpty() {
        return (
            <div className="line-pin">
                <button type="button" className="btn btn-outline-success btn-xs" onClick={this.addPeriod}>Adicionar Novo Período</button>
            </div>
        )
    }

    render() {
        if (!Array.isArray(this.props.periods) || this.props.periods.length <= 0)
            return this.renderEmpty()

        return this.props.periods.map((period, index) => {
            return this.renderPeriodsToEdit(period, index)
        });
    }
}


export default PeriodsEdit