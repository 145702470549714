import axios from 'axios'
import React from 'react'
import constants from '../../constants'
import withAuth from '../../components/withAuth'
import Breadcrumb from '../../components/Breadcrumb'
import DataPageEdit from '../../components/DataPageEdit'
import PeriodsEdit from './PeriodsEdit'
import GeneratePeriodsEdit from './GeneratePeriods'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

class CouponEdit extends DataPageEdit {

    constructor(props) {
        super(props);
        this.slugApi += "coupongenerate";
    }

    onGenerateSlug = (e) => {
        e.preventDefault()
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 10; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
        }

        const itens = this.state.itens
        this.slug = result.join('')

        for (let item of itens) {
            item.slug = result.join('')
        }

       this.setState({ itens })
    }

    onNewCodes = (e) => {
        if (e.target.files.length > 0 ) {
            let file = e.target.files[0]

            const data = new FormData()
            const url = `/api/v1/${this.getEventSlug()}/couponsgenerate/codes/${this.state.itens[0].id}`

            data.append('file', file)
            data.append('filename', file.name)

            fetch(url, {
                method: 'POST',
                headers: {
                    'x-access-token': localStorage.getItem("token")
                },
                body: data
            }).then((response) => {
                if (response.status === 200) {
                    const options = {
                        title: 'Códigos',
                        message: "Os códigos estão sendo processados. Você pode navegar pelo site enquanto trabalhamos nisso.",
                        buttons: [ { label: 'OK' } ]
                    };
            
                    confirmAlert(options)
                }

                if (response.status === 406) {
                    const options = {
                        title: 'Códigos',
                        message: "Parece que esse arquivo não é compatível",
                        buttons: [ { label: 'OK' } ]
                    };
            
                    confirmAlert(options)
                }
            })
        }


        

        
    }

    openGetCode = (e) => {
        e.preventDefault()
        this.setState({newCode: true})
    }

    closeGetCode = (e) => {
        e.preventDefault()
        this.setState({newCode: false})
    }

    toggleGeneratePeriods = (e) => {
        if (e)
            e.preventDefault()

        let { generatePeriods } = this.state
        generatePeriods = !generatePeriods

        this.setState({generatePeriods})
    }

    onChangePeriodGenerate = (type, periods) => {
        let currentPeriods = this.getFieldValue("periods")

        if (type == 0 && Array.isArray(currentPeriods))
            periods = currentPeriods.concat(periods)

        this.onChangeFieldAllLocale({
            target: {
                type: "periods",
                name: "periods",
                value: periods
            }
        })

        this.toggleGeneratePeriods()
    }

    getCode = (e) => {
        e.preventDefault()
        const url = `/api/v1/${this.getEventSlug()}/coupongenerate/code/new/${this.slug}`
        const data = {
            email: this.state.email
        }

        axios.post(url, data, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            this.setState({code: res.data.code})
        }).catch( (error) => {
            if (error.response.status == 401) {
                localStorage.removeItem("token");
                window.open('/', "_self");
            }
        });
    }

    renderCodesRegion() {
        if (this.state.itens.length > 0) {
            return (
                <div className="form-row">
                    <div className="form-group col-12">
                        <label>Subir novos códigos</label>
                        <input type="file" accept="text/plain" className="form-control form-control-sm" onChange={this.onNewCodes} />
                    </div>
                    <div className="form-group col-12 col-md-3 col-lg-2">
                        <button className="btn btn-dark" onClick={this.openGetCode}>Obter um Código</button>
                    </div>
                </div>
            )
        }

        return (
            <div className="form-row">
                <p>Salve o provedor para poder subir os cupons</p>
            </div>
        )
    }

    renderGetNewCode() {
        if (this.state.newCode) {
            return (
                <div className={(`modalBackground open`)}>
                <div className="modalDialog">
                    <div className="content-title">
                        <h4 className="card-title">Obter um código</h4>
                    </div>
                    <div className="basic-form">
                        <form className="form-custom">
                            <div className="form-group col-12">
                                <label>E-mail</label>
                                <input type="text" className="form-control form-control-sm" placeholder="E-mail" name="email" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}} />
                            </div>
                            <div className="form-group col-12">
                                <label>Código</label>
                                <input type="text" readOnly={true} className="form-control form-control-sm" placeholder="Código" name="code" value={this.state.code} />
                            </div>
                            <div className="form-group col-12 modalBotoes">
                                <button className="btn btn-dark" onClick={this.getCode}>Obter Código</button>
                                <button className="btn btn-dark" onClick={this.closeGetCode}>Fechar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            )

        }
        return ""
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Provedor de Cupons</h4>
                                    {this.renderLanguage()}
                                </div>
                                <div className="basic-form">
                                    <form className="form-custom" onSubmit={this.onSave}>
                                        <div className="form-row">
                                            {this.renderTextInput2({ name: "title", label: "Título", placeholder: "Título do provedor de cupons"})}
                                        </div>
                                        <div className="form-row">
                                            {this.renderTextInput2({ name: "slug", label: "Slug", placeholder: "Slug da recuperação do código", col: 9})}
                                            <div className="col-3">
                                                <label>Gerar novo slug</label>
                                                <br/>
                                                <button className="form-control-sm btn btn-dark" onClick={this.onGenerateSlug}>Gerar Slug</button>
                                            </div>
                                            <div className="form-group col-12">
                                                <a href={(`${constants.url}coupons/${this.getFieldValue("slug")}`)} target="_new">{(`${constants.url}coupons/${this.getFieldValue("slug")}`)}</a>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            {this.renderTextInput2({ name: "instructions", label: "Instruções", placeholder: "Instruções", col_md: 12})}
                                        </div>
                                        <div className="form-row">
                                            {this.renderTextInput2({ name: "limit", label: "Limite Atingido", placeholder: "Limite Atingido", col_md: 12})}
                                        </div>
                                        <div className="form-row">
                                            <h3>Períodos de liberação de códigos</h3>
                                        </div>
                                        <div className="form-row">
                                        <div className="form-group col-12">
                                                <button type="button" className="btn btn-dark" onClick={this.toggleGeneratePeriods}>Gerar Diversos Períodos</button>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <PeriodsEdit name="periods" periods={this.getFieldValue("periods")} onChange={this.onChangeFieldAllLocale} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <button type="submit" className="btn btn-dark">Salvar</button>
                                            </div>
                                        </div>
                                        {this.renderMessage()}
                                        <hr/>
                                        <h3>Códigos de Cupons</h3>
                                        <div className="form-row">
                                            <p>Total de cupons: <b>{this.getFieldValue("countCoupons")}</b></p>
                                        </div>
                                        <div className="form-row">
                                            <p>Total de cupons usados: <b>{this.getFieldValue("usedCountCoupons")}</b></p>
                                        </div>
                                        {this.renderCodesRegion()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderGetNewCode()}
                <GeneratePeriodsEdit 
                    show={this.state.generatePeriods} 
                    toggleEvent={this.toggleGeneratePeriods}
                    onChangePeriod={this.onChangePeriodGenerate} />
            </div>
        );
    }
}


export default withAuth(CouponEdit)