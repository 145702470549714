import React from "react";
import withAuth from "../../components/withAuth";
import DataPageEdit from "../../components/DataPageEdit";
import Breadcrumb from "../../components/Breadcrumb";
import SelectFK from "../../components/SelectFK";
import ActionsEdit from "../../components/ActionsEdit";
import CommandsEdit from "../../components/CommandsEdit";
import ImageSelector from "../../components/ImageSelector";
import constants from "../../constants";

class GroupTotemEdit extends DataPageEdit {
  constructor(props) {
    super(props);
    this.slugApi += "groupTotem";
  }

  render() {
    return (
      <div key={`group_totem_${this.state.language}`}>
        <Breadcrumb />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="content-title">
                  <h4 className="card-title">Grupo de totem</h4>
                  {this.renderLanguage()}
                </div>

                <div className="basic-form">
                  <form className="form-custom" onSubmit={this.onSave}>
                    <div className="form-row">
                      {this.renderTextInputChangeAll(
                        "slug",
                        `${
                          constants.url
                        }${this.getEventSlug()}/grouptotem/${this.getFieldValue(
                          "slug"
                        )}`,
                        "Slug: Deixe vazio para gerar automaticamente",
                        12
                      )}
                    </div>
                    <div className="form-row">
                      {this.renderTextInput(
                        "title",
                        "Nome",
                        "Nome do grupo",
                        12,
                        12,
                        6
                      )}
                      {this.renderTextInputChangeAll(
                        "minplaytime",
                        "Tempo Mínimo de Avaliação",
                        "Tempo mínimo em minutos para um usuário avaliar o jogo",
                        12,
                        12,
                        6
                      )}
                    </div>
                    <div className="form-row">
                      {this.renderTextInputChangeAll(
                        "playtime",
                        "Tempo de Jogatina",
                        "Tempo em minutos da sessão do usuário",
                        12,
                        12,
                        6
                      )}
                      {this.renderTextInputChangeAll(
                        "extratime",
                        "Tempo Extra",
                        "Tempo extra que o usuário ganha ao responder um questionário",
                        12,
                        12,
                        6
                      )}
                    </div>
                    <div className="form-row">
                      <SelectFK
                        label="Games"
                        multiple="true"
                        eventRequired="true"
                        slug="games"
                        locale={this.state.language}
                        name="games"
                        value={this.getFieldValue("games")}
                        onChange={this.onChangeFieldAllLocale}
                        col="12"
                        colmd="6"
                      />
                      <SelectFK
                        label="Totens"
                        multiple="true"
                        slug="totens"
                        locale={this.state.language}
                        name="totens"
                        eventRequired="true"
                        value={this.getFieldValue("totens")}
                        onChange={this.onChangeFieldAllLocale}
                        col="12"
                        colmd="6"
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-12">
                        <button type="submit" className="btn btn-dark">
                          Salvar em todos idiomas
                        </button>
                      </div>
                    </div>
                    {this.renderMessage()}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAuth(GroupTotemEdit);
