import React from 'react';
import withAuth from '../components/withAuth';
import DataPageEdit from '../components/DataPageEdit';
import Breadcrumb from '../components/Breadcrumb';
import ImageSelector from "../components/ImageSelector";

class FestivalArea extends DataPageEdit {

    constructor(props) {
        super(props);
        this.slugApi += "festivalArea"

        this.addAttentionFields([
            'link'
        ])
    }
    

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Área do Festival</h4>
                                    {this.renderLanguage()}
                                </div>
                                <div className="basic-form">
                                    <form className="form-custom" onSubmit={this.onSave}>
                                        <div className="form-row">
                                            {this.renderTextInput("title", "Nome", "Nome da área")}
                                        </div>
                                        <div className="form-row">
                                            {this.renderTextInput("link", "Link", "Link da área")}
                                        </div>
                                        <div className="form-row">
                                            {this.renderTextInputChangeAll("order", "Ordem", "Ordem")}
                                        </div>
                                        <div className="form-row">
                                            <ImageSelector label="Imagem do card" col="12" name="image" width="150" height="90" value={this.getFieldValue("image")} onChange={this.onChangeField} />
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <button type="submit" className="btn btn-dark">Salvar em todos idiomas</button>
                                            </div>
                                        </div>
                                        {this.renderMessage()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withAuth(FestivalArea);