import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { renderTextInput, renderSelect } from "../../components/renderControls";


class SyncVote extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            syncs: [],
            syncCode: "",
            syncMessage: null,
        }
    }

    componentDidMount() {
        this.loadSyncsTotens()
    }

    loadSyncsTotens() {
        if (this.props.voteId != null) {
            var slugEvent = this.getEventSlug()
            let url = `/api/v1/${slugEvent}/vote/${encodeURI(this.props.voteId)}/syncs`
            axios.get(url, {headers: {
                'x-access-token': localStorage.getItem("token")
            }}).then((res) => {
                const syncs = res.data
                this.setState({syncs, loading: false});
            }).catch( (error) => {
                console.log(error)
                if (error?.response?.status == 401) {
                    localStorage.removeItem("token");
                    window.open('/', "_self");
                }
            });
        }
        else {
            this.setState({loading: false});
        }
    }

    getEventSlug() {
        return localStorage.getItem("bigjogos.adm.event.slug")
    }

    getCurrentTime = (timeUtc) => {
        if (timeUtc == null) {
            return ""
        }

        return moment.utc(timeUtc).local().format("DD/MM/YYYY HH:mm:ss")
    }

    trySynctotem = () => {
        if (this.state.syncCode == null 
            || this.state.syncCode.length != 6) {
            return;
        }

        var slugEvent = this.getEventSlug()
        let url = `/api/v1/${slugEvent}/vote/${encodeURI(this.props.voteId)}/sync/${encodeURI(this.state.syncCode)}`
            axios.post(url, {headers: {
                'x-access-token': localStorage.getItem("token")
            }}).then((res) => {
                console.log(res)
                this.setState({syncMessage: null, syncCode: ""})
                this.loadSyncsTotens()
            }).catch( (error) => {
                console.error(error)
                if (error?.response?.status == 401) {
                    localStorage.removeItem("token");
                    window.open('/', "_self");
                }
                else if (error?.response?.status == 404) {
                    this.setState({syncMessage: "Código de sincronização inválido!"})
                }
                else if (error?.response?.status == 409) {
                    this.setState({syncMessage: "Esse código de totem já foi sincronizado!"})
                }
                else {
                    this.setState({syncMessage: "Erro ao sincronizar totem!"})
                }
            });
    }

    onDeleteConfirm = (e, id, code) => {
        e.preventDefault();
        const options = {
            title: 'Remover Item',
            message: "Você tem certeza que deseja remover o totem de votação '" + code + "' permanentemente?",
            buttons: [
              {
                label: 'Sim',
                onClick: () => this.onDelete(id)
              },
              {
                label: 'Não'
              }
            ]
        };

        confirmAlert(options);
    }

    onDelete = (id) => {
        var slugEvent = this.getEventSlug()
        let url = `/api/v1/${slugEvent}/vote/${this.props.voteId}/sync/${id}`;
        axios.delete(url, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            if (res.status == 200) {
                this.setState({syncMessage: null, syncCode: ""})
                this.loadSyncsTotens()
            }
            else {
                this.setState({syncMessage: "Erro ao remover totem!"})
            }
        });
    }

    renderNotSaved() {
        return (
            <div className="form-row">
                <div className="form-group col-12">
                    <div className="alert alert-warning">
                        Salve a votação primeiro para poder sincronizar os totens de votação!
                    </div>
                </div>
            </div>
        )
    }

    renderSyncNewTotem = () => {
        return (
            <>
                <h3>Sincronizar Novo Totem</h3>
                <div className="form-row">
                    {renderTextInput(
                        {
                          name: "totemCode",
                          label: "Código de Sinconização",
                          col: 10,
                          col_md: 4,
                          maxlength: 6,
                          value: this.state.syncCode,
                          onChange: (e) => { this.setState({syncCode: e.target.value}) },
                          placeholder: "Digite o código de sincronização",
                        }
                      )}

                    <div className="form-group col-2">
                        <button type="button" 
                                className="btn btn-dark" 
                                onClick={this.trySynctotem}
                                style={{marginTop: "33px"}}>
                          Sincronizar
                        </button>
                    </div>
                </div>
                {this.state.syncMessage != null &&
                    <div className="form-row">
                        <div className="form-group col-12">
                            <div className="alert alert-danger">{this.state.syncMessage}</div>
                        </div>
                    </div>
                }
            </>
        )
    }

    renderListTotem = () => {
        return (
            <>
                <h3>Totens Sincronizados</h3>
                <div className="table-responsive">
                <table className="table verticle-middle table-lista-big">
                    <thead>
                      <tr>
                        <th scope="col">Código</th>
                        <th scope="col">Criado em</th>
                        <th scope="col">Última Comunicação</th>
                        <th scope="col">Última Sincronização</th>
                        <th scope="col">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                        {this.renderItemListTotem()}
                    </tbody>
                  </table>
                </div>
            </>
        )
    }
    
    renderItemListTotem = () => {
        if (this.state.syncs == null
            || this.state.syncs.length == 0) {
            return (
                <tr>
                    <td colSpan="5" style={{textAlign: "left"}}>
                        Nenhum totem sincronizado
                    </td>
                </tr>
            )
        }

        return this.state.syncs?.map((sync) => {
            return (
                <tr key={`sync_totem_${sync.id}`}>
                    <td>{sync.code}</td>
                    <td>{this.getCurrentTime(sync.created)}</td>
                    <td>{this.getCurrentTime(sync.last_ping)}</td>
                    <td>{this.getCurrentTime(sync.last_sync)}</td>
                    <td>
                        <span>
                        <a
                            href="#"
                            onClick={(e) => {
                                this.onDeleteConfirm(e, sync.id, sync.code)
                            }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Remover">
                            <i className="fa fa-close color-danger"></i>
                        </a>
                        </span>
                    </td>
                </tr>
            )
        })
    }

    render() {
        if (this.props.voteId) {
            return (
                <>
                    {this.renderSyncNewTotem()}
                    <hr />
                    {this.renderListTotem()}
                </>
            )
        } else {
            return this.renderNotSaved();
        }
    }
}

export default SyncVote;