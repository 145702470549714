import axios from 'axios';
import React, { Component } from 'react';
import SelectFK from '../../components/SelectFK';
import withAuth from '../../components/withAuth';
import Breadcrumb from '../../components/Breadcrumb';
import fileDownload  from 'js-file-download'

class Export extends Component {

    constructor(props) {
        super(props)

        this.state = {
            game: null
        }
    }

    changeGame = (e) => {
        let game = e.target.value;
        this.setState({game})
    }

    downloadReport = (e, name, group = null) => {
        e.preventDefault()
        var slugEvent = localStorage.getItem("bigjogos.adm.event.slug")
        let url = `/api/v1/report/${slugEvent}/${name}`

        if (group)
            url += `?${group}=${this.state.game}`

        axios.get(url, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            let fileName = `${name}.csv`

            if (group)
                fileName = `${this.state.game}_${fileName}`

            fileDownload(res.data, fileName);
        })
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Relatório do Evento</h4>
                                </div>
                                <div className="basic-form">
                                    <form className="form-custom">
                                        <div className="form-row">
                                            <div className="col-12 col-md-4">
                                                <button className="btn btn-dark form-group w-100" onClick={(e) => this.downloadReport(e, "registration")}>Exportar Usuários Registrados</button>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <button className="btn btn-dark form-group w-100" onClick={(e) => this.downloadReport(e, "registrationTotem")}>Exportar Usuários Registrados nos Totens</button>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <button className="btn btn-dark form-group w-100" onClick={(e) => this.downloadReport(e, "views")}>Exportar Views</button>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <button className="btn btn-dark form-group w-100" onClick={(e) => this.downloadReport(e, "likes")}>Exportar Likes</button>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <button className="btn btn-dark form-group w-100" onClick={(e) => this.downloadReport(e, "vipused")}>Exportar VIPs</button>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <button className="btn btn-dark form-group w-100" onClick={(e) => this.downloadReport(e, "feedback")}>Feedback</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Relatório por Games</h4>
                                </div>
                                <div className="basic-form">
                                    <form className="form-custom">
                                        <div className="form-row">
                                                <SelectFK 
                                                    label="Game" 
                                                    emptyElement="true" 
                                                    eventRequired="true"
                                                    slug="games" 
                                                    locale="en" 
                                                    name="game" 
                                                    value={this.state.game}
                                                    onChange={this.changeGame} col="12" colmd="4" />
                                        </div>
                                        <hr />
                                        <div className="form-row">
                                            <div className="col-12 col-md-4">
                                                <button className="btn btn-dark form-group w-100" onClick={(e) => this.downloadReport(e, "views", "games")}>Exportar Views</button>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <button className="btn btn-dark form-group w-100" onClick={(e) => this.downloadReport(e, "likes", "games")}>Exportar Likes</button>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <button className="btn btn-dark form-group w-100" onClick={(e) => this.downloadReport(e, "feedback", "games")}>Feedback</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withAuth(Export)