import React from 'react';
import constants from '../../constants';
import SelectFK from '../../components/SelectFK';
import withAuth from '../../components/withAuth';
import Breadcrumb from '../../components/Breadcrumb';
import ActionsEdit from '../../components/ActionsEdit';
import CommandsEdit from '../../components/CommandsEdit';
import DataPageEdit from '../../components/DataPageEdit';
import ImageSelector from "../../components/ImageSelector";


class GameEdit extends DataPageEdit {
    
    constructor(props) {
        super(props);
        this.slugApi += "game";

        this.classifier = {
            "pt": {
                0: "Livre",
                10: "+10",
                12: "+12",
                14: "+14",
                16: "+16",
                18: "+18"
            },
            "en": {
                0: "Everyone",
                10: "+10",
                12: "+12",
                14: "+14",
                16: "+16",
                18: "+18"
            }
        }

        this.poweredby = {
            "pt": {
                "none": "Nenhum",
                "nuuvem": "Nuuvem",
                "steam": "Steam"
            },
            "en": {
                "none": "Nenhum",
                "nuuvem": "Nuuvem",
                "steam": "Steam"
            }
        }

        this.areas = {
            "pt": {
                "none": "Não Listado",
                "big_festival": "Big Festival",
                "panorama_brasil": "Panorama Brasil"
            },
            "en": {
                "none": "Não Listado",
                "big_festival": "Big Festival",
                "panorama_brasil": "Panorama Brasil"
            }
        }

        this.addAttentionFields([
            'studio',
            'video',
            'about',
            'instructions'
        ])
    }

    render() {
        return (
        <div key={`game_${this.state.language}`}>
            <Breadcrumb />
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="content-title">
                                <h4 className="card-title">Jogo</h4>
                                {this.renderLanguage()}
                            </div>
                            
                            <div className="basic-form">
                                <form className="form-custom" onSubmit={this.onSave}>
                                    <div className="form-row">
                                        {this.renderTextInputChangeAll("slug", `${constants.url}${this.getEventSlug()}/game/${this.getFieldValue("slug")}`, "Slug: Deixe vazio para gerar automaticamente", 12)}
                                    </div>
                                    <div className="form-row">
                                        {this.renderTextInput("title", "Nome", "Nome do jogo", 12, 12, 6)}
                                        {this.renderTextInput("studio", "Estúdio", "Estúdio do jogo", 12, 12, 6)}
                                    </div>
                                    <div className="form-row">
                                        <ImageSelector label="Imagem do Jogo" col="12" name="thumb" width="300" height="300" value={this.getFieldValue("thumb")} onChange={this.onChangeField} />
                                    </div>
                                    <div className="form-row">
                                        {this.renderTextArea("about", "Sobre o jogo", "Conte um pouco sobre o jogo...")}
                                    </div>
                                    <div className="form-row">
                                        {this.renderTextInput("country", "País", "País do jogo", 12, 12, 6)}
                                        {this.renderSelectChangeAll("age", "Classificação", this.classifier, 12, 12, 6)}
                                    </div>
                                    <div className="form-row">
                                        {this.renderTextInput("publisher", "Publisher", "Publisher do jogo", 12, 12, 6)}
                                        <SelectFK label="Powered By" emptyElement="true" slug="platforms" locale={this.state.language} name="powered" value={this.getFieldValue("powered")} onChange={this.onChangeFieldAllLocale} col="12" colmd="6" />
                                    </div>
                                    <div className="form-row">
                                        {this.renderTextInput("awards", "Premiações", "Premiações", 12, 12, 6)}
                                        {this.renderSelectChangeAll("area", "Área do Jogo", this.areas, 12, 12, 6)}
                                    </div>
                                    <div className="form-row">
                                        <SelectFK label="Categorias" multiple="true" slug="categories" locale={this.state.language} name="categories" value={this.getFieldValue("categories")} onChange={this.onChangeFieldAllLocale}  col="12" colmd="6" />
                                        <SelectFK label="Gêneros" multiple="true" slug="genres" locale={this.state.language} name="genres" value={this.getFieldValue("genres")} onChange={this.onChangeFieldAllLocale} col="12" colmd="6" />
                                    </div>
                                    <div className="form-row">
                                        {this.renderTextInput("video", "Vídeo de apresentação", "Link do vídeo", 12, 12, 6)}
                                        {this.renderTextInput("play", "Botão Jogar", "Link", 12, 12, 6)}
                                    </div>
                                    <div className="form-row">
                                        {this.renderTextArea("instructions", "Instruções de Jogo", "Conte os passos necessários para jogar...")}
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                            <div className="checkbox margin-left-0">
                                                <label>Habilitar número de visitantes ao vivo?</label>
                                                <div className="col-12">
                                                    <input className="js-switch" name="numberVisitors" checked={this.getFieldValue("numberVisitors")} type="checkbox" onChange={this.onChangeFieldAllLocale} />
                                                </div> 
                                            </div>
                                        </div>
                                        <div className="form-group col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                            <div className="checkbox">
                                                <label>Habilitar número de visualizações?</label>
                                                <div className="col-12">
                                                    <input className="js-switch_2" name="numberViews" checked={this.getFieldValue("numberViews")} type="checkbox" onChange={this.onChangeFieldAllLocale} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                            <div className="checkbox">
                                                <label>Status</label>
                                                <div className="col-12">
                                                    <input className="js-switch_3" name="status" checked={this.getFieldValue("status")} type="checkbox" onChange={this.onChangeFieldAllLocale} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Botões de Ações</h3>
                                    <hr/>
                                    <ActionsEdit name="actions" value={this.getFieldValue("actions")} onChange={this.onChangeField} />
                                    <h3>Comandos</h3>
                                    <hr/>
                                    <CommandsEdit name="controllers" locale={this.state.language} value={this.getFieldValue("controllers")} onChange={this.onChangeField} />
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <button type="submit" className="btn btn-dark">Salvar em todos idiomas</button>
                                        </div>
                                    </div>
                                    {this.renderMessage()}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default withAuth(GameEdit);