import React, { Component } from 'react';
import withAuth from '../../../components/withAuth';
import Breadcrumb from '../../../components/Breadcrumb';
import BasePageList from '../../../components/BasePageList';

class GenresGames extends BasePageList {

    constructor(props) {
        super(props);
        this.slugSingularApi = "genre";
        this.slugPluralApi = "genres";
    }

    renderGenres() {
        return this.state.itens.map(item => {
            return (
                <tr key={("genre_" + item.slug)}>
                    <td>{item.title}</td>
                    <td><span>
                        <a href={("/games/genre/" + item.slug)} data-toggle="tooltip" data-placement="top" title="Editar"><i className="fa fa-pencil color-muted m-r-5"></i> </a>
                        <a href="#" onClick={(e) => {this.onDeleteConfirm(e, item.slug, item.title)}} data-toggle="tooltip" data-placement="top" title="Remover"><i className="fa fa-close color-danger"></i></a>
                    </span></td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Genêros/Plataformas</h4>       
                                <div className="table-responsive">
                                    <table className="table verticle-middle table-lista-big">
                                        <thead>
                                            <tr>
                                                <th scope="col">Genêros/Plataformas</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderGenres()}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <a href="/games/genre/" className="btn btn-dark" title="Novo Genêro">Novo Genêro</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuth(GenresGames);