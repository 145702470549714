import axios from 'axios';
import React, { Component } from 'react';
import withAuth from '../../components/withAuth';
import DataPageEdit from '../../components/DataPageEdit';
import Breadcrumb from '../../components/Breadcrumb';

class AdminUsersEdit extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            login: "",
            password: "",
            confirmPassword: "",
            alert: null
        }
    }

    componentDidMount() {
        if (this.props.id) {
            let url = "/api/v1/admin/" + this.state.id;
            axios.get(url, {headers: {
                'x-access-token': localStorage.getItem("token")
            }}).then((res) => {
                this.setState({login: res.data.login});
            }).catch( (error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.open('/', "_self");
                }
            });
        }
    }

    onChangeField = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    onSave = (e) => {
        e.preventDefault();
        const validateResult = this.validateData()

        if (validateResult == null) {
            let url = `/api/v1/admin/${this.state.id}`;

            const {login, password, confirmPassword} = this.state
            const id = login

            const user = {id, login, password, confirmPassword}

            axios.post(url, user, { headers: {
                'x-access-token': localStorage.getItem("token")
            }}).then((res) => {
                if (res.status == 200) {
                    this.showMessage();
                } else {
                    this.showMessage(false, "Ocorreu um erro na hora de salvar!")
                }
            });
        }
        else {
            this.showMessage(false, validateResult)
        }
    }

    validateData() {
        if (!this.state.login) {
            return "O campo login é obrigatório"
        }

        if ((this.state.password || this.state.confirmPassword)
            && this.state.password != this.state.confirmPassword)
            return "A Nova senha e a sua confirmação devem ser iguais"

        if (!this.props.id && (!this.state.password || !this.state.confirmPassword)) {
            return "Usuário novo precisa ter uma senha"
        }

        return null
    }

    showMessage(success = true, message = "Dados salvos com sucesso!") {
        let alert = {
            success: success,
            message: message
        }

        this.setState({alert});
    }

    renderMessage() {
        if (this.state.alert == null)
            return null;

        let classAlertType = "success";

        if (!this.state.alert.success)
            classAlertType = "danger";

        return (
            <div className="form-row">
                <div className="form-group col-12">
                    <div className={("alert alert-" + classAlertType)}>{this.state.alert.message}</div>
                </div>
            </div>
        )
    }

    render() {
        return (
        <div>
            <Breadcrumb />
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="content-title">
                                <h4 className="card-title">Usuário</h4>
                            </div>
                            <div className="basic-form">
                                <form className="form-custom" onSubmit={this.onSave}>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <label>Login</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="E-mail de Acesso" name="login" value={this.state.login} onChange={this.onChangeField} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-6 col-md12">
                                            <label>Nova Senha</label>
                                            <input type="password" className="form-control form-control-sm" placeholder="Nova Senha" name="password"  value={this.state.password} onChange={this.onChangeField} />
                                        </div>
                                        <div className="form-group col-6 col-md12">
                                            <label>Confirmar Nova Senha</label>
                                            <input type="password" className="form-control form-control-sm" placeholder="Confirmação da Nova Senha" name="confirmPassword" value={this.state.confirmPassword} onChange={this.onChangeField} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <button type="submit" className="btn btn-dark">Salvar</button>
                                        </div>
                                    </div>
                                </form>
                                {this.renderMessage()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default withAuth(AdminUsersEdit);