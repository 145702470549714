import axios from 'axios'
import React, { Component } from 'react'
import withAuth from "../../components/withAuth"
import { renderTextInput, renderTextArea, renderCheckBox } from '../../components/renderControls'

const ACCOUNT = "AC22e398dd1b38e57e2f327319860e32a0"
const FROM_PHONE = "+15512092909"
const TOKEN = "376b379125dbc19ea72204fedaae88e5"

class Whatsapp extends Component {

    

    constructor(props) {
        super(props)

        this.state = {
            item: {
                account: ACCOUNT,
                token: TOKEN,
                from: FROM_PHONE,
                whats: true
            },
            message: ""
        }
    }

    changeFieldValue = (e) => {
        let value = ""
        let item = this.state.item

        if (item === null)
            item = {}

        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        else if (e.target.tagName === "SELECT" && e.target.multiple) {
            value = [];
            for (let option of e.target.selectedOptions) {
                value.push(option.value);
            };
        }
        else {
            value = e.target.value;
        }

        item[e.target.name] = value;

        this.setState({item})
    }

    validateToSend = (e) => {
        this.sendAll()
    }

    sendAll = async () => {

        let url = "/api/v1/tools/whatsapp"

        axios.post(url, this.state.item, { headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            if (res.status === 200) {
                this.setState({message: res.data})
            } else {
                this.setState({message: "Erro ao enviar whatsapp"})
            }
        })
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h1>Envio de WhatsApp e SMS</h1>
                                </div>

                                <form>
                                    {renderTextInput({
                                        name: "account",
                                        label: "Conta",
                                        description: "Chave da conta de acesso a API",
                                        required: true,
                                        onChange: this.changeFieldValue,
                                        value: ACCOUNT,
                                        col_md: 6
                                    })}

                                    {renderTextInput({
                                        name: "token",
                                        label: "Token",
                                        description: "Token de acesso a API",
                                        value: TOKEN,
                                        required: true,
                                        onChange: this.changeFieldValue,
                                        col_md: 6
                                    })}

                                    {renderCheckBox({
                                        name: "whats",
                                        label: "WhatsApp",
                                        onChange: this.changeFieldValue,
                                        value: true,
                                        required: false
                                    })} 

                                    {renderTextInput({
                                        name: "from",
                                        label: "De",
                                        description: "Telefone de origem da mensagem",
                                        required: true,
                                        onChange: this.changeFieldValue,
                                        value: FROM_PHONE,
                                        col_md: 6
                                    })}

                                    {renderTextArea({
                                        name: "to",
                                        label: "Para",
                                        description: "Telefone de envio, um telefone por linha com o código de páis. Ex: +5511999998888",
                                        required: true,
                                        onChange: this.changeFieldValue,
                                    })}

                                    {renderTextArea({
                                        name: "body",
                                        label: "Mensagem",
                                        description: "Mensagem a ser enviada",
                                        required: true,
                                        onChange: this.changeFieldValue,
                                    })}

                                    <div dangerouslySetInnerHTML={{ __html: this.state.message }} />

                                    <button type="button" className="btn btn-dark" onClick={this.validateToSend}>Enviar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAuth(Whatsapp)