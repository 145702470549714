import React, { Component } from 'react'
import DragAndDrop from './DragAndDrop'

class FileList extends Component {
    state = {
        files: []
    }

    componentDidMount() {
        const files = this.props.files

        if (files)
            this.setState({files})
    }

    onChangeFile = (e) => {
        const reader = new FileReader()
        this.handleDrop(e.target.files)
    }

    handleDrop = (files) => {
        let fileList = this.state.files
        
        for (var i = 0; i < files.length; i++) {
            if (!files[i].name) return
            fileList.push(files[i])
        }

        const limit = this.props.limit ?? 1

        if (fileList.length > limit)
            fileList = fileList.slice(fileList.length - limit)

        this.setState({files: fileList})

        const e = {
            target: {
                tagName: "FileList",
                files: fileList,
                name: this.props.name,
                value: fileList[0].name
            }
        }

        this.props.onChange?.(e)
    }

    render() {
        return (
            <div className="fileInputDrargDrop">
                <input type="file"  id="fileInput" onChange={this.onChangeFile} />
                <label htmlFor="fileInput" className="w-100">
                    <DragAndDrop handleDrop={this.handleDrop}>
                        <section className="col-12">
                            {this.state.files.map((file, i) =>
                                <div key={i}>{file.name}</div>
                            )}
                            {(!Array.isArray(this.state.files) || !this.state.files.length) &&
                                <div>Escolha arquivos ou arraste aqui</div>
                            }
                        </section>
                    </DragAndDrop>
                </label>
            </div>
        )
    }
}

export default FileList