import axios from 'axios'
import React, { Component } from 'react';
import withAuth from '../components/withAuth';

class Home extends Component {

    clearCache = async () => {
        const url = "/api/v1/cache/clear"

        try {
            await axios.get(url)
            console.log("Cache limpo")
        }
        catch(ex) {
            console.log(`Erro ao limpar o cache ${url}: ${ex.toString()}`)
        }
    }

    render() {
        return (
        <>
            <h1>HOME</h1>

            <div className="row">
                <div className='col-12'>
                    <h2>Ações</h2>
                </div>
                <div className="col-3">
                    <div className="card">
                        <button className="btn btn-primary" onClick={this.clearCache}>Limpar cache</button>
                    </div>
                </div>
            </div>
        </> 
        )
    }

}
export default withAuth(Home);