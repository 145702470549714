import axios from 'axios';
import React, { Component } from 'react';

import imgLogo from '../assets/images/logo.svg'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
          email: '',
          password: '',
          message: ''
        };
    }

    onChangeField = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    onLogin = (e) => {
        e.preventDefault();
        const { email, password } = this.state;

        axios.post('/api/v1/login/', { email, password })
            .then ((result) => {
                console.log(result);
                if (result.status === 200 && result.data.auth === true) {
                    localStorage.setItem("token", result.data.token);
                    window.open('/', "_self");
                }
                else {
                    this.setState({message: "Usuário ou senha incorretos!"})
                }

            });
    }

    render() {
        return (
            <div className="login-screen">
                <div className="login-bg h-100">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100">
                            <div className="col-xl-6">
                                <div className="form-input-content">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="logo text-center">
                                                <a href="/"><img src={imgLogo} alt="BIG Festival" /></a>
                                            </div>
                                            <h4 className="text-center m-t-15">Portal Administrativo - BiG Festival</h4>
                                            <form className="m-t-30 m-b-30" onSubmit={this.onLogin}>
                                                <div className="form-group">
                                                    <label>E-mail</label>
                                                    <input type="email" name="email" className="form-control" placeholder="E-mail" value={this.state.email}  onChange={this.onChangeField} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Senha</label>
                                                    <input type="password" name="password" className="form-control" placeholder="Senha"  value={this.state.password}  onChange={this.onChangeField} />
                                                </div>

                                                <div className="form-group">
                                                    {this.state.message}
                                                </div>

                                                <div className="text-center m-b-15 m-t-15">
                                                    <button type="submit" className="btn btn-primary">Entrar</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Login;