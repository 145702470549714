import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
            <div className="footer">
                <div className="copyright">
                    <p>Copyright <a href="https://www.bigfestival.com.br" target="_blank" title="Big Festival">Big Festival</a></p>
                </div>
            </div>
        );
    }
}

export default Footer;