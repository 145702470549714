import React from 'react';
import withAuth from '../../../components/withAuth';
import Breadcrumb from '../../../components/Breadcrumb';
import BasePageEdit from '../../../components/BasePageEdit';
import ImageSelector from "../../../components/ImageSelector";

import { renderTextInput, renderTextArea } from '../../../components/renderControls';

class HomeEdit extends BasePageEdit {
    constructor(props) {
        super(props)

        var slugEvent = localStorage.getItem("bigjogos.adm.event.slug")

        this.slugApi = "event/home"
        this.slug = slugEvent
    }

    generateSlug() {
        return this.getEventSlug()
    }

    addMultipleFields = (name) => {
        let fields = this.getFieldValue(name);

        if (!Array.isArray(fields))
        fields = [];

        fields.push({});
        this.onChangeField({ target: { name: name, value: fields } });
    }

    removeMultipleFields = (name, index) => {
        let fields = this.getFieldValue(name);
        fields.splice(index, 1);
        this.onChangeField({ target: { name: name, value: fields } });
    }

    getMultipleValue = (item, field) => {
        if (item == null)
            return "";

        return item[field] ?? "";
    }

    setMultipleValue = (name, index, field, value) => {
        console.log(field)
        let items = this.getFieldValue(name);
        let item = items[index];

        if (item == null)
            return;

        item[field] = value;

        console.log(items);

        this.onChangeField({ target: { name: name, value: items } });
    }


    addBanner = () => {
        this.addMultipleFields("banners")
    }

    removeBanner = (index) => {
        this.removeMultipleFields("banners", index);
    }

    renderBanners = () => {
        const banners = this.getFieldValue("banners");

        if (!Array.isArray(banners))
            return (<></>);

        return banners.map((banner, i) => {
            return (
                <>
                    <div key={`banner_${this.state.language}_${i}`} className="form-row">
                        <h4 className='col-12'>Destaque {i+1}</h4>

                        {renderTextInput({ value: this.getMultipleValue(banner, "title"), name: "title", label: "Título", onChange: (e) => this.setMultipleValue("banners", i, "title", e.target.value), col_md: 6})}
                        {renderTextInput({ value: this.getMultipleValue(banner, "link"), name: "link", label: "Link", onChange: (e) => this.setMultipleValue("banners", i, "link", e.target.value), col_md: 6})}

                        <ImageSelector label="Imagem do Banner" col="12" name="image" width="370" height="210" value={banner.image} onChange={(e) => this.setMultipleValue("banners", i, "image", e.target.value)} />
                        
                        {renderTextArea({ value: this.getMultipleValue(banner, "description"), name: "description", label: "Descrição", onChange: (e) => this.setMultipleValue("banners", i, "description", e.target.value)})}

                        <button type="button" className="btn btn-outline-success btn-xs" onClick={() => this.removeBanner(i)}>Remover Destaque</button>
                    </div>
                    <hr />
                </>
            );
        });
    }


    addTalks = () => {
        this.addMultipleFields("talks")
    }

    removeTalks = (index) => {
        this.removeMultipleFields("talks", index);
    }

    renderTalks = () => {
        const talks = this.getFieldValue("talks");

        if (!Array.isArray(talks))
            return (<></>);

        return talks.map((talk, i) => {
            return (
                <>
                    <div key={`talk_${this.state.language}_${i}`} className="form-row">
                        <h4 className='col-12'>Palestra {i+1}</h4>

                        {renderTextInput({ value: this.getMultipleValue(talk, "title"), name: "title", label: "Título", onChange: (e) => this.setMultipleValue("talks", i, "title", e.target.value), col_md: 6})}
                        {renderTextInput({ value: this.getMultipleValue(talk, "link"), name: "link", label: "Link", onChange: (e) => this.setMultipleValue("talks", i, "link", e.target.value), col_md: 6})}

                        <ImageSelector label="Imagem da Palestra" col="12" name="image" width="270" height="210" value={talk.image} onChange={(e) => this.setMultipleValue("talks", i, "image", e.target.value)} />

                        <button type="button" className="btn btn-outline-success btn-xs" onClick={() => this.removeTalks(i)}>Remover Palestra</button>
                        
                    </div>
                    <hr />
                </>
            )
        });
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Home</h4>
                                    {this.renderLanguage()}
                                </div>
                                <div className="datamap">
                                    <div id="world-datamap">
                                        <form onSubmit={this.onSave}>
                                            <h3>Textos</h3>
                                            <div className='form-row'>
                                                {this.renderTextInput("subtitle", "Sub Título", "Sub Título da página", 12, 12, 6)}
                                                {this.renderTextInput("description", "Descrição", "Descrição que aparece na página", 12, 12, 6)}
                                            </div>

                                            <h3>Links</h3>

                                            <div className='form-row'>
                                                {this.renderTextInput("link_talks", "Link Palestra", "Link do botão na área de palestras", 12, 12, 6)}
                                                {this.renderTextInput("link_playlist", "Link Playlist", "Link do botão na área de playlist", 12, 12, 6)}
                                                
                                            </div>

                                            <div className='form-row'>
                                            {this.renderTextInput("playlist", "Playlist", "ID da playlist", 12, 12, 6)}
                                            </div>

                                            <h3>Destaques</h3>
                                            {this.renderBanners()}

                                            <button type="button" className="btn btn-outline-success btn-xs" onClick={this.addBanner}>Adicionar Destaque</button>

                                            <hr />
                                            <h3>Palestras</h3>
                                            {this.renderTalks()}

                                            <button type="button" className="btn btn-outline-success btn-xs" onClick={this.addTalks}>Adicionar Palestra</button>

                                            <hr />

                                            <button type="submit" className="btn btn-dark">Salvar em todos idiomas</button>
                                        </form>
                                        {this.renderMessage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAuth(HomeEdit)