import axios from 'axios'
import React, { Component } from 'react';
import withAuth from '../../components/withAuth';
import Breadcrumb from '../../components/Breadcrumb';
import BasePageList from '../../components/BasePageList';


class CouponsList extends BasePageList {
    constructor(props) {
        super(props);
        this.slugSingularApi += "coupongenerate"
        this.slugPluralApi += "couponsgenerate"
        this.keyField = "id"

        this.state = {
            itens: [],
            editOpen: false,
            email: "",
            type: 0,
            limit: 0,
            qtdCupon: 1,
            loadingCode: false
        }
    }

    renderItens() {
        return this.state.itens.map(item => {
            return (
                <tr key={("coupon_" + item.id)}>
                    <td>{item.title}</td>
                    <td><span>
                    <a href={("/coupon/" + item.slug)} data-toggle="tooltip" data-placement="top" title="Editar"><i className="fa fa-pencil color-muted m-r-5"></i> </a>
                        <a href="#" onClick={(e) => {this.onDeleteConfirm(e, item.id, item.title)}} data-toggle="tooltip" data-placement="top" title="Remover"><i className="fa fa-close color-danger"></i></a>
                    </span></td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Provedores de Cupons</h4>
                                <div className="table-responsive">
                                    <table className="table verticle-middle table-lista-big">
                                        <thead>
                                            <tr>
                                                <th scope="col">Título</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderItens()}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <a href="/coupon/" className="btn btn-dark" title="Novo Provedor">Novo Provedor</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAuth(CouponsList)