import React, { Component } from 'react';
import withAuth from '../../components/withAuth';
import Breadcrumb from '../../components/Breadcrumb';
import BasePageList from '../../components/BasePageList';

class PublishersList extends BasePageList {

    constructor(props) {
        super(props);
        this.slugSingularApi += "publisher";
        this.slugPluralApi += "publishers";
    }

    renderPublishers() {
        return this.state.itens.map(item => {
            return (
                <tr key={("publisher_" + item.slug)}>
                    <td>{item.title}</td>
                    <td><span>
                        <a href={("/publisher/" + item.slug)} data-toggle="tooltip" data-placement="top" title="Editar"><i className="fa fa-pencil color-muted m-r-5"></i> </a>
                        <a href="#" onClick={(e) => {this.onDeleteConfirm(e, item.slug, item.title)}} data-toggle="tooltip" data-placement="top" title="Remover"><i className="fa fa-close color-danger"></i></a>
                    </span></td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Publishers</h4>
                                <div className="table-responsive">
                                    <table className="table verticle-middle table-lista-big">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderPublishers()}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <a href="/publisher/" className="btn btn-dark" title="Nova Publisher">Nova Publisher</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuth(PublishersList);