import axios from "axios";
import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import Login from "./pages/Login";

import MapsEdit from "./pages/Events/Maps/Edit";
import HomeEdit from "./pages/Events/Home/EditHome";
import EventEdit from "./pages/Events/Edit";
import EventChange from "./pages/Events/Change";

import CategoriesGames from "./pages/Games/Categories/List";
import CategoryGames from "./pages/Games/Categories/Edit";

import GenresGames from "./pages/Games/Genres/List";
import GenreGames from "./pages/Games/Genres/Edit";

import PlatformsList from "./pages/Games/Platforms/List";
import PlatformEdit from "./pages/Games/Platforms/Edit";

import FestivalAreas from "./pages/FestivalAreas";
import FestivalArea from "./pages/FestivalArea";

import SponsorsList from "./pages/Sponsors/List";
import SponsorEdit from "./pages/Sponsors/Edit";

import StandsList from "./pages/Stands/List";
import StandsEdit from "./pages/Stands/Edit";

import PublishersList from "./pages/Publishers/List";
import PublishersEdit from "./pages/Publishers/Edit";

import InfluencersList from "./pages/Influencers/List";
import InfluencersEdit from "./pages/Influencers/Edit";

import GamesList from "./pages/Games/List";
import GameEdit from "./pages/Games/Edit";

import TotensList from "./pages/Totens/List";
import TotemEdit from "./pages/Totens/Edit";

import VotesList from "./pages/Votes/ListVotes";
import VoteEdit from "./pages/Votes/EditVote";

import GroupsTotensList from "./pages/GroupsTotens/List";
import GroupTotemEdit from "./pages/GroupsTotens/Edit";

import VipList from "./pages/Vip/List";

import AdminUsersList from "./pages/AdminUsers/List";
import AdminUsersEdit from "./pages/AdminUsers/Edit";

import CouponsList from "./pages/Coupons/List";
import CouponEdit from "./pages/Coupons/Edit";

import Export from "./pages/Report/Export";
import ViewsReport from "./pages/Report/ViewsReport";

import GenerateLinkEmail from "./pages/Tools/GenerateLinkEmail";
import UserImportTotem from "./pages/Tools/UserImportTotem";
import Whatsapp from "./pages/Tools/Whatsapp";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      login: false,
    };
  }

  componentDidMount() {
    var slugEvent = localStorage.getItem("bigjogos.adm.event.slug");

    if (!slugEvent) {
      let url = "/api/v1/events/";
      axios
        .get(url, {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          localStorage.setItem(
            "bigjogos.adm.event.slug",
            res.data.slice(-1)[0].slug
          );
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status == 401) {
            this.setState({ login: true, isLoading: false })
          }
        });
    } else {
      this.validateAccount()
    }
  }

  validateAccount() {
    let url = "/api/v1/account/validate"

    axios.get(url, { headers: {
        'x-access-token': localStorage.getItem("token")
    }}).then((res) => {
        if (res.status === 200) {
          this.setState({ login: false, isLoading: false })
        }
        else {
          this.setState({ login: true, isLoading: false })
        }
    }).catch((error) => {
      this.setState({ login: true, isLoading: false })
    })
  }

  renderLoading() {
    return <h2>Loading...</h2>;
  }

  renderLogin() {
    return (
      <div className="App">
        <Login />
      </div>
    );
  }

  renderSystem() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/login/">
              <Login />
            </Route>
            <Route exact path="/games/categories/">
              <CategoriesGames />
            </Route>
            <Route
              exact
              path="/games/category/:id"
              render={(matchProps) => (
                <CategoryGames {...matchProps.match.params} />
              )}
            />
            <Route exact path="/games/category/">
              <CategoryGames />
            </Route>
            <Route exact path="/games/genres/">
              <GenresGames />
            </Route>
            <Route
              exact
              path="/games/genre/:id"
              render={(matchProps) => (
                <GenreGames {...matchProps.match.params} />
              )}
            />
            <Route exact path="/games/genre/">
              <GenreGames />
            </Route>
            <Route exact path="/games/platforms/">
              <PlatformsList />
            </Route>
            <Route
              exact
              path="/games/platform/:id"
              render={(matchProps) => (
                <PlatformEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/games/platform/">
              <PlatformEdit />
            </Route>
            <Route exact path="/festivalareas/">
              <FestivalAreas />
            </Route>
            <Route
              exact
              path="/festivalarea/:id"
              render={(matchProps) => (
                <FestivalArea {...matchProps.match.params} />
              )}
            />
            <Route exact path="/festivalarea/">
              <FestivalArea />
            </Route>
            <Route exact path="/sponsors/">
              <SponsorsList />
            </Route>
            <Route
              exact
              path="/sponsor/:id"
              render={(matchProps) => (
                <SponsorEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/sponsor/">
              <SponsorEdit />
            </Route>
            <Route exact path="/stands/">
              <StandsList />
            </Route>
            <Route
              exact
              path="/stand/:id"
              render={(matchProps) => (
                <StandsEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/stand/">
              <StandsEdit />
            </Route>
            <Route exact path="/publishers/">
              <PublishersList />
            </Route>
            <Route
              exact
              path="/publisher/:id"
              render={(matchProps) => (
                <PublishersEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/publisher/">
              <PublishersEdit />
            </Route>
            <Route exact path="/influencers/">
              <InfluencersList />
            </Route>
            <Route
              exact
              path="/influencer/:id"
              render={(matchProps) => (
                <InfluencersEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/influencer/">
              <InfluencersEdit />
            </Route>
            <Route exact path="/map/">
              <MapsEdit />
            </Route>
            <Route exact path="/home/">
              <HomeEdit />
            </Route>

            <Route exact path="/totens/">
              <TotensList />
            </Route>
            <Route
              exact
              path="/totem/:id"
              render={(matchProps) => (
                <TotemEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/totem/">
              <TotemEdit />
            </Route>

            <Route exact path="/votes/">
              <VotesList />
            </Route>
            <Route
              exact
              path="/vote/:id"
              render={(matchProps) => (
                <VoteEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/vote/">
              <VoteEdit />
            </Route>

            

            <Route exact path="/groupstotens/">
              <GroupsTotensList />
            </Route>
            <Route
              exact
              path="/grouptotem/:id"
              render={(matchProps) => (
                <GroupTotemEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/grouptotem/">
              <GroupTotemEdit />
            </Route>

            <Route exact path="/games/">
              <GamesList />
            </Route>
            <Route
              exact
              path="/game/:id"
              render={(matchProps) => <GameEdit {...matchProps.match.params} />}
            />
            <Route exact path="/game/">
              <GameEdit />
            </Route>
            <Route exact path="/admins/">
              <AdminUsersList />
            </Route>
            <Route
              exact
              path="/admin/:id"
              render={(matchProps) => (
                <AdminUsersEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/admin/">
              <AdminUsersEdit />
            </Route>
            <Route exact path="/vips/">
              <VipList />
            </Route>

            <Route exact path="/coupons/">
              <CouponsList />
            </Route>
            <Route
              exact
              path="/coupon/:id"
              render={(matchProps) => (
                <CouponEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/coupon/">
              <CouponEdit />
            </Route>

            <Route exact path="/confirmEmail/">
              <GenerateLinkEmail />
            </Route>

            <Route exact path="/importUserTotem/">
              <UserImportTotem />
            </Route>

            <Route exact path="/event/change">
              <EventChange />
            </Route>
            <Route
              exact
              path="/event/:id"
              render={(matchProps) => (
                <EventEdit {...matchProps.match.params} />
              )}
            />
            <Route exact path="/event/">
              <EventEdit />
            </Route>
            <Route exact path="/report/export">
              <Export />
            </Route>
            <Route exact path="/report/views">
              <ViewsReport />
            </Route>

            <Route exact path="/tools/whatsapp">
              <Whatsapp />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }

  render() {
    if (this.state.login) return this.renderLogin();
    else if (this.state.isLoading) return this.renderLoading();
    else return this.renderSystem();
  }
}

export default App;
