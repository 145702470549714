import styled, { css } from "styled-components";

const dragActive = css`
  border-color: #78e5d5 !important;
`;

const dragReject = css`
  border-color: #e57878 !important;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone",
})`
  border: 1px dashed #ddd !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  transition: height 0.2s ease !important;
  flex: 1;
  margin-right: 10px;
  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
`;

const messageColors = {
  default: "#999",
  error: "#e57878",
  success: "#78e5d5",
};

export const UploadMessage = styled.p`
  display: flex !important;
  color: ${(props) => messageColors[props.type || "default"]};
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
  padding: 15px 0 !important;
`;
