import React, { Component } from 'react';

class ActionsEdit extends Component {


    addAction = () => {
        let actions = this.props.value;

        if (!Array.isArray(actions))
        actions = [];

        
        actions.push({ target: "_blank" });

        this.onActionChangeValue(actions);
    }

    removeAction = (index) => {
        let actions = this.props.value;
        actions.splice(index, 1);
        this.onActionChangeValue(actions);
    }

    onChangeField = (e, index) => {
        let value = "";
        let actions = this.props.value;

        if (e.target.type === "checkbox")
            value = e.target.checked;
        else
            value = e.target.value;

        let action = actions[index];
        action[e.target.name] = value;
        actions[index] = action;

        this.onActionChangeValue(actions);
    }

    onActionChangeValue(pins) {
        if (this.props.onChange != undefined) {
            let event = {
                target: {
                    type: "actionEdit",
                    name: this.props.name,
                    value: pins
                }
            };

            this.props.onChange(event);
        }
    }

    renderActions() {
        return this.props.value.map((item, index) => {
            let last = "";

            if (index >= (this.props.value.length - 1))
                last = (<button type="button" className="btn btn-outline-success btn-xs" onClick={this.addAction}>adicionar</button>);

            return (
                <div className="line-pin">
                    <div className="form-row">
                        <div className="form-group col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                            <label>Nomo do Botão {(index + 1)}</label>
                            <input type="text" name="label" className="form-control form-control-sm" placeholder="Texto do botão" value={item.label} onChange={(e) => this.onChangeField(e, index)} />
                        </div>
                        <div className="form-group col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                            <label>Link de destino</label>
                            <input type="text" name="link" className="form-control form-control-sm" placeholder="Link do botão" value={item.link} onChange={(e) => this.onChangeField(e, index)} />
                        </div>
                        <div className="form-group col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                            <label>Abrir o link em:</label>
                            <select name="target" className="form-control form-control-sm" value={item.target} onChange={(e) => this.onChangeField(e, index)}>
                                <option value="_self">Na mesma aba</option>
                                <option value="_blank">Uma nova aba</option>
                            </select>
                        </div>
                        <div className="form-group buttons col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                            <button type="button" className="btn btn-outline-primary btn-xs" onClick={() => {this.removeAction(index)}} >remover</button>
                            {last}
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderEmpty() {
        return (
            <div className="line-pin">
                <button type="button" className="btn btn-outline-success btn-xs" onClick={this.addAction}>Adicionar Nova Action</button>
            </div>
        )
    }

    render() {
        if (!Array.isArray(this.props.value) || this.props.value.length <= 0)
            return this.renderEmpty();

        return this.renderActions();
    }
}

export default ActionsEdit;