import axios from 'axios'
import React, { Component } from 'react'
import withAuth from '../../components/withAuth'
import Breadcrumb from '../../components/Breadcrumb'


class EventChange extends Component {

    constructor(props) {
        super(props)

        this.state = {
          itens: []
        }
    }

    componentDidMount() {
        let url = "/api/v1/events/"
        axios.get(url, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            let itens = res.data
            this.setState({itens});
        }).catch( (error) => {
            console.log(error)
            if (error?.response?.status == 401) {
                localStorage.removeItem("token");
                window.open('/', "_self");
            }
        })
    }

    onClickChangeEvent(slug) {
        localStorage.setItem("bigjogos.adm.event.slug", slug)
        window.open('/', "_self")
    }

    renderEvents() {
        return this.state.itens?.map(item => {
            return (
                <tr key={("event_" + item.slug)}>
                    <td>{item.title}</td>
                    <td>{item.slug}</td>
                    <td><a href="#" className="btn btn-dark" title="Selecionar" onClick={ () => {this.onClickChangeEvent(item.slug)}}>Selecionar</a></td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Escolher Evento</h4>
                                </div>
                                <div className="table-responsive">
                                <table className="table verticle-middle table-lista-big">
                                    <thead>
                                        <tr>
                                            <th scope="col">Título</th>
                                            <th scope="col">Slug</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderEvents()}
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAuth(EventChange)