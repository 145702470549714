import React, { Component, useState, useCallback } from "react";
import { uniqueId } from "lodash";
import filesize from "filesize";
import Papa from "papaparse";
import ProgressBar from "@ramonak/react-progress-bar";
import withAuth from "../../components/withAuth";
import Breadcrumb from "../../components/Breadcrumb";
import Upload from "../../components/Upload";
import {
  Content,
  ContentLoading,
  ContentInfo,
  Text,
  ContainerTable,
  Table,
} from "./styles";
import Api from "../../services/api";

function UserImportTotem() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedSuccess, setUploadedSuccess] = useState([]);
  const [uploadedFail, setUploadedFail] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleUpload = (files) => {
    const _uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploadedFiles(_uploadedFiles);
  };

  const progressStatus = (item, totalUsers) => {
    setProgress(parseInt(Math.round((item * 100) / totalUsers)));
  };

  const sendUserBd = (users, item = 1, totalUsers) => {
    if (users.length !== 0) {
      users[0].slug = localStorage.getItem("bigjogos.adm.event.slug");
      Api.post(`registrationTotem/`, users[0])
        .then((res) => {
          progressStatus(item, totalUsers);
          const _uploadedSuccess = uploadedSuccess.map((file) => file);
          let newArray = uploadedSuccess;
          newArray.push(res.data);
          setUploadedSuccess(_uploadedSuccess.concat(res.data));
          users.shift();
          if (users.length > 0) sendUserBd(users, item + 1, totalUsers);
        })
        .catch((err) => {
          progressStatus(item, totalUsers);
          const _uploadedFail = uploadedFail.map((file) => file);
          let newArray = uploadedFail;
          newArray.push(err.data);
          setUploadedFail(_uploadedFail.concat(err.data));
          users.shift();
          if (users.length != 0) sendUserBd(users, item + 1, totalUsers);
        });
    }
  };

  return (
    <div>
      <Breadcrumb />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="content-title">
                <h4 className="card-title">Importar Usuarios Totem</h4>
              </div>
              <div className="basic-form">
                <Content>
                  <Upload onUpload={handleUpload} file={uploadedFiles} />
                  <button
                    className="btn btn-dark"
                    onClick={() => {
                      if (!loading) {
                        Papa.parse(uploadedFiles[0].file, {
                          complete: (result) => {
                            setLoading(true);
                            sendUserBd(result.data, 1, result.data.length);
                          },
                          header: true,
                        });
                      }

                      if (progress === 100) {
                        setUploadedFiles([]);
                        setUploadedSuccess([]);
                        setUploadedFail([]);
                        setProgress(0);
                        setLoading(false);
                      }
                    }}
                    disabled={!uploadedFiles.length}
                  >
                    {loading === true && progress !== 100 && "Carregando"}
                    {loading === true && progress === 100 && "Nova Importação"}
                    {loading === false && "Importar Usuários"}
                  </button>
                </Content>

                {loading && (
                  <ContentLoading>
                    <ProgressBar
                      completed={progress}
                      height={"50px"}
                      bgColor={"#77DD77"}
                    />
                    <ContentInfo>
                      <Text>
                        Sucesso: <b>{uploadedSuccess.length}</b>
                      </Text>
                      <Text>
                        Falha: <b>{uploadedFail.length}</b>
                      </Text>
                    </ContentInfo>
                    {uploadedFail.length > 0 && (
                      <ContainerTable>
                        <h3>Falhas</h3>
                        <Table>
                          <thead>
                            <tr>
                              <th scope="col">Evento ID</th>
                              <th scope="col">Email</th>
                              <th scope="col">Descrição</th>
                            </tr>
                          </thead>
                          <tbody>
                            {uploadedFail.map((user, index) => {
                              return (
                                <tr key={index}>
                                  <td>{user.event_id}</td>
                                  <td>{user.email}</td>
                                  <td>
                                    <p>Usuário já cadastrado</p>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </ContainerTable>
                    )}
                  </ContentLoading>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuth(UserImportTotem);
