import React from 'react';
import withAuth from '../../../components/withAuth';
import BasePageEdit from '../../../components/BasePageEdit';
import Breadcrumb from '../../../components/Breadcrumb';
import ImageSelector from "../../../components/ImageSelector";
import PinMap from '../../../components/PinMap';

class MapsEdit extends BasePageEdit {
    constructor(props) {
        super(props)

        var slugEvent = localStorage.getItem("bigjogos.adm.event.slug")

        this.slugApi = "event"
        this.slug = slugEvent
    }

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">Mapa do Evento</h4>
                                    {this.renderLanguage()}
                                </div>
                                <div className="datamap">
                                    <div id="world-datamap">
                                        <form onSubmit={this.onSave}>
                                            <div className="form-row">
                                                <ImageSelector label="Imagem do Mapa" col="12" name="map" width="1920" height="1080" value={this.getFieldValue("map")} onChange={this.onChangeField} />
                                            </div>
                                            <PinMap name="pins" size="22" image={this.getFieldValue("map")} locale={this.state.language} label="Mapa do Evento" pins={this.getFieldValue("pins")} onChange={this.onChangeField}  />
                                            <button type="submit" className="btn btn-dark">Salvar em todos idiomas</button>
                                        </form>
                                        {this.renderMessage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuth(MapsEdit)