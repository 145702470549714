import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  align-content: space-between;
  width: 100%;
`;

export const ContentLoading = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const ContentInfo = styled.div`
  display: flex;
  margin-top: 5px;
  padding-right: 5px;
  justify-content: flex-end;
`;

export const Text = styled.p`
  display: flex !important;
  font-size: 16px;
  & + p {
    margin-left: 5px;
  }
  b {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const ContainerTable = styled.div.attrs({
  className: "table-responsive",
})`
  /* display: table; */
  width: 100%;
  h3 {
    text-align: center;
    color: #454545;
    font-size: 18px;
    font-weight: 500;
  }
`;

export const Table = styled.table.attrs({
  className: "table verticle-middle table-lista-big",
})`
  width: 100%;

  th,
  td {
    text-align: left !important;
  }
`;
