import { v4 as uuidv4 } from 'uuid'
import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import ImageSelector from '../../components/ImageSelector'

class EditItemVote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editIndex: null
        }
    }

    addItem = () => {
        let itens = this.props.itens;
        console.log("itens", itens);

        if (!Array.isArray(itens))
            itens = [];

        itens.push({ 
            id: uuidv4(),
            vote_id: this.props.voteId,
        });

        this.onItensChangeValue(itens);
    }

    removeItem = (index) => {
        let itens = this.props.itens;
        itens.splice(index, 1);
        this.onItensChangeValue(itens);
    }

    onChangeField = (e, index) => {
        let value = "";
        let itens = this.props.itens;

        if (e.target.type === "checkbox")
            value = e.target.checked;
        else
            value = e.target.value;

        let item = itens[index];
        item[e.target.name] = value;
        itens[index] = item;

        this.onItensChangeValue(itens);
    }

    onItensChangeValue(itens) {
        if (this.props.onChange != undefined) {
            let event = {
                target: {
                    type: "itens",
                    name: this.props.name,
                    value: itens
                }
            };

            this.props.onChange(event);
        }
    }

    renderNotSaved() {
        return (
            <div className="form-row">
                <div className="form-group col-12">
                    <div className="alert alert-warning">
                        Salve a votação primeiro para poder adicionar itens!
                    </div>
                </div>
            </div>
        )
    }

    renderEmpty() {
        return (
            <div className="form-row">
                <button type="button" className="btn btn-outline-success btn-xs" onClick={this.addItem}>Adicionar Novo Item</button>
            </div>
        )
    }

    renderItemEdit(item, index) {

        return (
            <Accordion.Item eventKey={index}>
                <Accordion.Header>Item {(index + 1)} - {item.name ?? "(Sem nome)"}</Accordion.Header>
                <Accordion.Body>
                    <div key={(`item_${this.props.name}_${index}`)} className="form-row line-multiline">
                        <ImageSelector label="Imagem" col="6" name="image" width="351" height="336" value={item.image} onChange={(e) => this.onChangeField(e, index)} />
                            
                        <div className="form-group col-12 col-sm-12 col-md-6">
                            <div className='mb-3'>
                                <label>Nome</label>
                                <input type="text" name="name" className="form-control form-control-sm" placeholder="Nome (Aparece na primeira linha no totem)" value={item.name} onChange={(e) => this.onChangeField(e, index)} />
                            </div>
                            <div className='mb-3'>
                                <label>Título</label>
                                <input type="text" name="title" className="form-control form-control-sm" placeholder="Título (Aparece na segunda linha no totem)" value={item.title} onChange={(e) => this.onChangeField(e, index)} />
                            </div>
                            <div className='mb-3'>
                                <button type="button" className="btn btn-outline-primary btn-xs" onClick={() => {this.removeItem(index)}}>remover</button>
                            </div>
                        </div>    
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        );
    }

    renderList = () => {
        return this.props.itens.map((item, index) => {
            return this.renderItemEdit(item, index)
        });
    }

    render() {
        if (!this.props.voteId) {
            return this.renderNotSaved();
        }

        if (!Array.isArray(this.props.itens) || this.props.itens.length <= 0)
            return this.renderEmpty();

        return (
            <>
                <Accordion>
                    {this.renderList()}
                </Accordion>
                <button type="button" className="btn btn-outline-success btn-xs" onClick={this.addItem}>Adicionar Item</button>
            </>
        )
    }
}

export default EditItemVote