import React, { Component } from 'react';
import imgLogo from "../assets/images/logo.svg";

class Header extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showEventMenu: false
        }
    }

    onClickMenu = (e) => {
        if (document.body.classNameList.contains("mini-nav"))
            document.body.classNameList.remove('mini-nav');
        else
            document.body.classNameList.add('mini-nav');
    }

    onClickEventMenu = (e) => {
        e.preventDefault()
        const showEventMenu = !this.state.showEventMenu
        this.setState({ showEventMenu })
    }

    render() {
        const eventSlug = localStorage.getItem("bigjogos.adm.event.slug")
        let eventMenuClass = "drop-down dropdown-profile animated bounceInDown"

        if (this.state.showEventMenu)
            eventMenuClass += " active"

        return (
            <div className="header">
                <div className="nav-header">
                    <div className="brand-logo"><a href="index.html" title="BIG"><img src={imgLogo} alt="BIG" /></a></div>
                    <div className="nav-control">
                        <div className="hamburger" onClick={this.onClickMenu}><span className="line"></span> <span className="line"></span> <span className="line"></span>
                        </div>
                    </div>
                </div>
                <div className="header-content">
                    <div className="header-right">
                        <ul>
                            <li className="icons"><a href="#"><i className="mdi mdi-account f-s-20" aria-hidden="true"></i></a>
                                <div className="drop-down dropdown-profile animated bounceInDown">
                                    <div className="dropdown-content-body">
                                        <ul>
                                            <li><a href="page-account.html" title="Minha Conta"><i className="mdi mdi-settings"></i> <span>Minha Conta</span></a>
                                            </li>
                                            <li><a href="page-login.html" title="Sair"><i className="icon-power"></i> <span>Sair</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="header-right">
                        <ul>
                            <li>
                            <a onClick={this.onClickEventMenu}>Evento {eventSlug} <i className="mdi mdi-arrow-down f-s-17" aria-hidden="true"></i></a>
                                <div className={eventMenuClass}>
                                    <div className="dropdown-content-body">
                                        <ul>
                                            <li><a href="/event/change" title="Trocar Evento"><i className="mdi mdi-refresh"></i> <span>Trocar Evento</span></a></li>
                                            <li><a href="/event/" title="Novo Evento"><i className="mdi mdi-creation"></i> <span>Novo Evento</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

}

export default Header;