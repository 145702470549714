import axios from 'axios';
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

class BaseIdPageEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            slug: "",
            item: {},
            alert: null,
            loading: true,
            title: "",
        }

        var slugEvent = this.getEventSlug()

        this.slugApi = `${slugEvent}/`
        this.id = this.props.id
    }

    getEventSlug() {
        return localStorage.getItem("bigjogos.adm.event.slug")
    }

    componentDidMount() {
        let id = this.id;
        if (id != null) {
            let url = "/api/v1/" + this.slugApi + "/" + encodeURI(id)
            axios.get(url, {headers: {
                'x-access-token': localStorage.getItem("token")
            }}).then((res) => {
                let item = this.onNewData(res.data)
                this.setState({item, loading: false});
            }).catch( (error) => {
                console.log(error)
                if (error?.response?.status == 401) {
                    localStorage.removeItem("token");
                    window.open('/', "_self");
                }
            });
        }
        else {
            let item = this.onNewData({})

            this.setState({item, loading: false});
        }
    }

    onNewData(item) {
        return item;
    }

    onChangeField = (e) => {
        let value = "";
        let item = this.state.item;

        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        else if (e.target.tagName === "SELECT" && e.target.multiple) {
            value = [];
            for (let option of e.target.selectedOptions) {
                value.push(option.value);
            };
        }
        else {
            value = e.target.value;
        }

        item[e.target.name] = value;

        this.setState({item, alert: null});
    }

    getFieldValue(key) {
        let item = this.state.item;

        if (key in item)
            return item[key];

        return "";
    }

    getDataToSave() {
        return this.state.item;
    }

    validateData(item) {
        return null;
    }

    validateAttentionFields(item) {
        return true
    }



    onSave = (e) => {
        e.preventDefault();
        let item = this.getDataToSave()
        let validateResult = this.validateData(item)

        if (!validateResult) {
            let url = "/api/v1/" + this.slugApi + "/" + this.id;
            axios.post(url, item, { headers: {
                'x-access-token': localStorage.getItem("token")
            }}).then((res) => {
                if (res.status == 200) {
                    this.id = res.data.id

                    let attention = this.validateAttentionFields(item)

                    if (attention)
                        this.showMessage()
                    else
                        this.showMessage(true, "Dados salvos com sucesso, mas parece que alguns campos importantes em alguns idiomas não foram preenchidos.")
                } else {
                    this.showMessage(false, "Ocorreu um erro na hora de salvar!")
                }
            });
        }
        else {
            this.showMessage(false, validateResult)
        }
    }

    showMessage(success = true, message = "Dados salvos com sucesso!") {
        let alert = {
            success: success,
            message: message
        }

        this.setState({alert});
    }

    renderMessage() {
        if (this.state.alert == null)
            return null;

        let classAlertType = "success";

        if (!this.state.alert.success)
            classAlertType = "danger";

        return (
            <div className="form-row">
                <div className="form-group col-12">
                    <div className={("alert alert-" + classAlertType)}>{this.state.alert.message}</div>
                </div>
            </div>
        )
    }

    renderLoading() {
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="content-title">
                                    <h4 className="card-title">{this.state.title}</h4>
                                </div>
                                <div className="basic-form">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderPage() {
        return (<></>)
    }

    render() {
        if (this.state.loading)
            return this.renderLoading()
        else
            return this.renderPage()
    }
}

export default BaseIdPageEdit;