import React, { Component } from 'react';


class PinMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editIndex: null
        }
    }

    addPin = () => {
        let pins = this.props.pins;

        if (!Array.isArray(pins))
            pins = [];

        pins.push({ target: "_new" });
        let editIndex = pins.length - 1;

        this.setState({ editIndex });
        this.onPinChangeValue(pins);
    }

    removePin = (index) => {
        let pins = this.props.pins;
        pins.splice(index, 1);
        this.onPinChangeValue(pins);
    }

    onChangeField = (e, index) => {
        let value = "";
        let pins = this.props.pins;

        if (e.target.type === "checkbox")
            value = e.target.checked;
        else
            value = e.target.value;

        let pin = pins[index];
        pin[e.target.name] = value;
        pins[index] = pin;

        this.onPinChangeValue(pins);
    }

    onMapPositionClick = (e) => {
        if (this.state.editIndex != null) {
            const pw = (e.nativeEvent.offsetX * 100) / e.target.offsetWidth;
            const ph = (e.nativeEvent.offsetY * 100) / e.target.offsetHeight;

            let pins = this.props.pins;
            let pin = pins[this.state.editIndex];
            pin["left"] = pw;
            pin["top"] = ph;
            pins[this.state.editIndex] = pin;

            this.setState({ editIndex: null});
            this.onPinChangeValue(pins);
        }
    }

    onMoveClick = (index) => {
        this.setState({ editIndex: index} );
    }

    onPinChangeValue(pins) {
        if (this.props.onChange != undefined) {
            let event = {
                target: {
                    type: "pinmap",
                    name: this.props.name,
                    value: pins
                }
            };

            this.props.onChange(event);
        }
    }

    renderPinPoints() {
        if (this.state.editIndex == null) {
            if (!Array.isArray(this.props.pins))
                return null;

            return (
                <div className="points">
                    {this.props.pins.map((pin, index) => {
                        let left = 0;
                        let top = 0;

                        if ("left" in pin)
                            left = pin["left"];

                        if ("top" in pin)
                            top = pin["top"];

                        const marginLeft = parseInt(this.props.size, 10) / 2;
                        const marginTop = parseInt(this.props.size, 10) / 2;

                        left = `${left}%`;
                        top = `${top}%`;

                        return (
                            <div key={(`pinPoints_${this.props.name}_${this.props.locale}_${index}`)} className="point" style={{left, top, "marginLeft": -marginLeft, "marginTop": -marginTop }}>
                                <a href="#" data-toggle="tooltip" data-placement="right" title="" data-original-title=""></a>
                                <div className="efeito"></div>
                            </div>
                        );
                    })}                 
                </div>
            );
        }
    }

    renderPins() {

        if (!Array.isArray(this.props.pins) || this.props.pins.length <= 0)
            return this.renderEmpty();

        return this.props.pins.map((pin, index) => {
            if (index == this.state.editIndex)
                return this.renderPinToPosition(pin, index);
            else
                return this.renderPinToEdit(pin, index);
        });
    }

    renderPinToEdit(pin, index) {
        let last = "";

        if (index >= (this.props.pins.length - 1))
            last = (<button type="button" className="btn btn-outline-success btn-xs" onClick={this.addPin}>adicionar</button>);

        return (
            <div key={(`pin_${this.props.name}_${this.props.locale}_${index}`)} className="line-pin">
                <div className="form-row">
                    <div className="form-group col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <label>Pin {(index+1)}</label>
                        <input type="text" name="title" className="form-control form-control-sm" placeholder="Texto do tooltip" value={pin.title} onChange={(e) => this.onChangeField(e, index)} />
                    </div>
                    <div className="form-group col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                        <label>Link de destino</label>
                        <input type="text" name="link" className="form-control form-control-sm" placeholder="Link do pin" value={pin.link} onChange={(e) => this.onChangeField(e, index)} />
                    </div>
                    <div className="form-group col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                        <label>Abrir o link em:</label>
                        <select name="target" className="form-control form-control-sm" id="com2" value={pin.target} onChange={(e) => this.onChangeField(e, index)}>
                            <option value="_blank">Uma nova aba</option>
                            <option value="_self">Na mesma aba</option>
                        </select>
                    </div>
                    <div className="form-group buttons col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                        <button type="button" className="btn btn-outline-success btn-xs" onClick={() => {this.onMoveClick(index)}} >mover</button>
                        <button type="button" className="btn btn-outline-primary btn-xs" onClick={() => {this.removePin(index)}}>remover</button>
                        {last}
                    </div>
                </div>
            </div>
        );
    }

    renderPinToPosition(pin, index) {
        return (
            <div key={(`pinEdit_${this.props.name}__${this.props.locale}_${index}`)} className="line-pin select-position">
                <label>Clique no mapa para indicar uma posição para o PIN</label>
            </div>
        );
    }

    renderEmpty() {
        return (
            <div className="line-pin">
                <button type="button" className="btn btn-outline-success btn-xs" onClick={this.addPin}>Adicionar Novo Pin</button>
            </div>
        )
    }

    render() {
        return(
            <div key={(`pinMap_${this.state.props?.length}`)}>
                <div className="form-row estande">
                    <div className="form-group col-12 col-sm-12	col-md-12 col-lg-12 col-xl-12">
                        <label>{this.props.label}</label>
                        <div className="content-estande">
                            {this.renderPinPoints()}
                            <img src={this.props.image} alt="Estande" onClick={this.onMapPositionClick} />
                        </div>
                    </div>
                </div>
                {this.renderPins()}
            </div>
        );
    }
}


export default PinMap;