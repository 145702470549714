import axios from 'axios'
import React, { Component } from 'react';
import withAuth from '../../components/withAuth';
import Breadcrumb from '../../components/Breadcrumb';
import BasePageList from '../../components/BasePageList';

import fileDownload  from 'js-file-download'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class VipsList extends BasePageList {

    constructor(props) {
        super(props);
        this.slugSingularApi += "vip"
        this.slugPluralApi += "vips"
        this.keyField = "code"

        this.state = {
            itens: [],
            editOpen: false,
            email: "",
            type: 0,
            limit: 0,
            qtdCupon: 1,
            loadingCode: false,
            openPack: []
        }
    }

    openNewCode = (event) => {
        event.preventDefault()
        this.setState({editOpen: true})
    }

    closeNewCode = (event) => {
        event.preventDefault()
        this.setState({editOpen: false})
    }

    createCode = (event) => {
        event.preventDefault()

        const item = {
            email: this.state.email,
            type: parseInt(this.state.type),
            limit: parseInt(this.state.limit),
            qtdCupon: parseInt(this.state.qtdCupon)
        }

        this.setState({ loadingCode: true })
        
        var slugEvent = localStorage.getItem("bigjogos.adm.event.slug")
        let url = `/api/v1/${slugEvent}/vip` ;
        axios.post(url, item, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            this.setState({email: "", type: 0, limit: 0, editOpen: false })

            const options = {
                title: 'O código criado foi:',
                message: `Os códigos estão sendo gerados, o pack é ` + res.data.pack,
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {
                        this.setState({editOpen: false, loadingCode: false})
                    }
                  }
                ]
            };

            confirmAlert(options)
            
        }).catch( (error) => {
            if (error.response.status == 401) {
                localStorage.removeItem("token");
                window.open('/', "_self");
            }
        });
    }

    groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
    };

    toggleShowPack = (e, name) => {
        e.preventDefault()
        const openPack = this.state.openPack
        
        if (openPack.includes(name)) {
            const index = openPack.indexOf(name)
            openPack.splice(index, 1)
        }
        else {
            openPack.push(name)
        }

        console.log(openPack)
        this.setState({openPack})
    }

    renderGroupedItems(itensGrouped) {
        return Object.keys(itensGrouped).map((k) => {
            const icon = (this.state.openPack.includes(k)) ? "minus" : "plus"
            const title = (k !== "null") ? k : "(Sem Pack)"

            const pack = (
                <tr key={(`vip_group_${k}`)}>
                    <td colSpan="5">{title} <sub>({itensGrouped[k].length} itens)</sub></td>
                    <td>
                        <span>
                            <a href="#" onClick={(e) => this.toggleShowPack(e, k)} data-toggle="tooltip" data-placement="top" title="Remover"><i className={`fa fa-${icon}-circle`}></i></a>
                        </span>
                    </td>
                </tr>
            )

            if (this.state.openPack.includes(k)) {
                const lines = this.renderItens(itensGrouped[k])
                lines.unshift(pack)
                return lines
            }

            return pack
        })

        
    }

    renderItens(itens) {
        console.log(itens)
        return itens.map(item => {
            return (
                <tr key={("vip_" + item.code)}>
                    <td>{item.code}</td>
                    <td>{item.email}</td>
                    <td>{(item.type == 1 ? "Agendado" : "Livre")}</td>
                    <td>{item.used}</td>
                    <td>{item.limit}</td>
                    <td><span>
                        <a href="#" onClick={(e) => {this.onDeleteConfirm(e, item.code, item.code)}} data-toggle="tooltip" data-placement="top" title="Remover"><i className="fa fa-close color-danger"></i></a>
                    </span></td>
                </tr>
            );
        });
    }

    renderNewItem() {
        let open = ""

        if (this.state.editOpen)
            open = "open"

        let formHTML = (
            <div className="basic-form">
                <form className="form-custom">
                    <div className="form-group col-12">
                        <label>E-mail</label>
                        <input type="text" className="form-control form-control-sm" placeholder="E-mail (opcional)" name="email" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}} />
                    </div>
                    <div className="form-group col-12">
                        <label>Tipo</label>
                        <select className="form-control form-control-sm" placeholder="Tipo" name="type" value={this.state.type} onChange={(e) => {this.setState({type: e.target.value})}}>
                            <option value={0}>Acesso Imediato</option>
                            <option value={1}>Acesso Agendado</option>
                        </select>
                    </div>
                    <div className="form-group col-12">
                        <label>Limite de Usos (0 é infinito)</label>
                        <input type="number" className="form-control form-control-sm" name="limit" min="0" max="9999" maxlength="4" value={this.state.limit} onChange={(e) => {this.setState({limit: e.target.value})}} />
                    </div>
                    <div className="form-group col-12">
                        <label>Quantidade de cupons</label>
                        <input type="number" className="form-control form-control-sm" name="qtdCupon" min="1" max="9999" maxlength="4" value={this.state.qtdCupon} onChange={(e) => {this.setState({qtdCupon: e.target.value})}} />
                    </div>
                    <div className="form-group col-12 modalBotoes">
                        <button className="btn btn-dark" onClick={this.createCode}>Criar Código</button>
                        <button className="btn btn-dark" onClick={this.closeNewCode}>Cancelar</button>
                    </div>
                </form>
            </div>
        )

        if (this.state.loadingCode)
            formHTML = <div className="basic-form"><img src="/images/loading.gif" width="45px" height="45px" /></div>

        return (
            <div className={(`modalBackground ${open}`)}>
                <div className="modalDialog">
                    <div className="content-title">
                        <h4 className="card-title">Código VIP</h4>
                    </div>
                    {formHTML}
                </div>
            </div>
        )
    }

    render() {
        const itensGrouped = this.groupBy(this.state.itens, 'pack')

        return (
            <div>
                <Breadcrumb />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Códigos VIPs</h4>
                                <div className="table-responsive">
                                    <table className="table verticle-middle table-lista-big">
                                        <thead>
                                            <tr>
                                                <th scope="col">Código</th>
                                                <th scope="col">E-mail</th>
                                                <th scope="col">Tipo</th>
                                                <th scope="col">Usos do Código</th>
                                                <th scope="col">Limite</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderGroupedItems(itensGrouped)}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <button className="btn btn-dark" title="Novo Código" onClick={this.openNewCode}>Novo Código</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderNewItem()}
            </div>
        );
    }
}

export default withAuth(VipsList);