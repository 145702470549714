import React, { Component } from 'react';
import GallerySelector from './GallerySelector';

class ImageSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpenGallery: false
        }
    }

    openGallery = () => this.setState({ isOpenGallery: true })
    
    closeGallery = () => this.setState({ isOpenGallery: false })

    onImageSelected = (url) => {
        this.closeGallery();
        if (this.props.onChange != undefined) {
            let event = {
                target: {
                    type: "imageSelector",
                    name: this.props.name,
                    value: url
                }
            };

            this.props.onChange(event);
        }
    }
    
    render() {
        return (
            <div className={(`form-group col-${this.props.col} col-sm-${this.props.col} col-md-${this.props.col} col-lg-${this.props.col} col-xl-${this.props.col}`)}>
                <label>{this.props.label}</label>
                {
                    this.props.value &&
                    <p><img src={this.props.value} alt="Imagem selecionada" className="imageSelected" /></p>
                }
                <p><span>Dimensões ({this.props.width}px de largura por {this.props.height}px de altura)</span></p>
                <p><button type="button" className="btn" onClick={this.openGallery}>Selecionar uma Imagem</button></p>
                {
                    this.state.isOpenGallery &&
                    <GallerySelector
                        onClose={this.closeGallery}
                        onImageSelected={this.onImageSelected} />
                }
            </div>
        );
    }
}


export default ImageSelector;