import axios from 'axios';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class BasePageList extends Component {
    constructor(props) {
        super(props);

        var slugEvent = localStorage.getItem("bigjogos.adm.event.slug")

        this.slugSingularApi = `${slugEvent}/`
        this.slugPluralApi = `${slugEvent}/`
        this.keyField = "slug"

        this.state = {
          itens: []
        }
    }

    componentDidMount() {
        let url = "/api/v1/" + this.slugPluralApi;
        axios.get(url, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            let itens = res.data;
            this.setState({itens});
        }).catch( (error) => {
            if (error.response.status == 401) {
                localStorage.removeItem("token");
                window.open('/', "_self");
            }
        });
    }

    onDeleteConfirm = (e, slug, title) => {
        e.preventDefault();
        const options = {
            title: 'Remover Item',
            message: "Você tem certeza que deseja remover o item '" + title + "' permanentemente?",
            buttons: [
              {
                label: 'Sim',
                onClick: () => this.onDelete(slug)
              },
              {
                label: 'Não'
              }
            ]
        };

        confirmAlert(options);
    }

    onDelete = (slug) => {
        let url = "/api/v1/" + this.slugSingularApi + "/" + slug;
        axios.delete(url, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {

            if (res.status == 200) {
                let itens = this.state.itens;
                
                itens = itens.filter((item) => {
                    return item[this.keyField] != slug;
                });

                this.setState({itens});
            }
        });
    }
}

export default BasePageList;