import axios from "axios";

const slug = localStorage.getItem("bigjogos.adm.event.slug");
const Api = axios.create({
  baseURL: `http://localhost:5001/api/v1/${slug}/`,
  headers: {
    "x-access-token": localStorage.getItem("token"),
  },
});

Api.interceptors.response.use(
  (response) => response,
  (error) => {
    // handle error
    if (error.response) {
      return Promise.reject(error.response);
    }
  }
);

export default Api;
