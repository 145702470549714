import React, { Component } from "react";
import MetisMenu from "react-metismenu";
import classnames from "classnames";

class CustomLink extends Component {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    if (this.props.hasSubMenu) this.props.toggleSubMenu(e);
    else {
      this.props.activateMe({
        newLocation: this.props.to,
        selectedMenuLabel: this.props.label,
      });
    }
  }

  render() {
    return (
      <span className="nav-texto">
        <a
          className={classnames(
            this.props.className,
            this.props.active && this.props.classNameActive,
            this.props.hasActiveChild && this.props.classNameHasActiveChild,
            this.props.hasSubMenu && "has-arrow"
          )}
          href={this.props.to}
          onClick={
            this.props.hasSubMenu
              ? this.props.toggleSubMenu
              : this.props.activateMe
          }
          target={this.props.externalLink ? "_blank" : undefined}
          rel="noreferrer"
        >
          {this.props.children}
        </a>
      </span>
    );
  }
}

class Sidebar extends Component {
  createContent() {
    const eventSlug = localStorage.getItem("bigjogos.adm.event.slug");

    let content = [
      {
        icon: "view-dashboard",
        label: "Dashboard",
        to: "/",
      },
      {
        icon: "map",
        label: "Evento",
        content: [
          {
            label: "Mapa",
            to: "/map/",
          },
          {
            label: "Home",
            to: "/home/",
          },
          {
            label: "Configurações",
            to: "/event/" + eventSlug,
          },
        ],
      },
      {
        icon: "chart-bar",
        label: "Áreas do Festival",
        content: [
          {
            label: "Todas as Áreas",
            to: "/festivalareas/",
          },
          {
            label: "Nova Área",
            to: "/festivalarea/",
          },
        ],
      },
      {
        icon: "google-controller",
        label: "Jogos",
        content: [
          {
            label: "Todos os Jogos",
            to: "/games/",
          },
          {
            label: "Novo Jogo",
            to: "/game/",
          },
          {
            label: "Categorias",
            to: "/games/categories/",
          },
          {
            label: "Genêros",
            to: "/games/genres/",
          },
          {
            label: "Plataformas",
            to: "/games/platforms/",
          },
        ],
      },
      {
        icon: "table-edit",
        label: "Publishers",
        content: [
          {
            label: "Todos as Publishers",
            to: "/publishers/",
          },
          {
            label: "Nova Publisher",
            to: "/publisher/",
          },
        ],
      },
      {
        icon: "television",
        label: "Totens",
        content: [
          {
            label: "Todos os Totens",
            to: "/totens/",
          },
          {
            label: "Grupos de Totens",
            to: "/groupstotens/",
          },
          {
            label: "Novo Totem",
            to: "/totem/",
          },
        ],
      },
      {
        icon: "wunderlist",
        label: "Votação",
        content: [
          {
            label: "Todas as Votações",
            to: "/votes/",
          },
          {
            label: "Nova Votação",
            to: "/vote/",
          },
        ],
      },
      {
        icon: "face",
        label: "Influencers",
        content: [
          {
            label: "Todos as Influencers",
            to: "/influencers/",
          },
          {
            label: "Novo Influencer",
            to: "/influencer/",
          },
        ],
      },
      {
        icon: "diamond",
        label: "Estandes",
        content: [
          {
            label: "Todos os Estandes",
            to: "/stands/",
          },
          {
            label: "Novo Estande",
            to: "/stand/",
          },
        ],
      },
      {
        icon: "cash",
        label: "Propagandas",
        content: [
          {
            label: "Todas as Propagandas",
            to: "/sponsors/",
          },
          {
            label: "Nova Propagandas",
            to: "/sponsor/",
          },
        ],
      },
      {
        icon: "ticket",
        label: "Cupons",
        content: [
          {
            label: "Todos Provedores",
            to: "/coupons/",
          },
          {
            label: "Novo Provedor",
            to: "/coupon/",
          },
        ],
      },
      {
        icon: "account-multiple",
        label: "Usuários",
        content: [
          {
            label: "Todos os Usuários",
            to: "/admins/",
          },
          {
            label: "Novo Usuário",
            to: "/admin/",
          },
        ],
      },
      {
        icon: "account-card-details",
        label: "Códigos VIPs",
        to: "/vips/",
      },
      {
        icon: "chart-bar",
        label: "Relatórios",
        content: [
          {
            label: "Exportações",
            to: "/report/export",
          },
          {
            label: "Views",
            to: "/report/views",
          },
        ],
      },
      {
        icon: "settings",
        label: "Ferramentas",
        content: [
          {
            label: "Confirmação E-mail",
            to: "/confirmEmail/",
          },
          {
            label: "Importar Usuarios Totem",
            to: "/importUserTotem/",
          },
          {
            label: "WhatsApp",
            to: "/tools/whatsapp",
          }
        ],
      },
    ];

    return content;
  }

  render() {
    return (
      <div className="nk-sidebar">
        <div className="nk-nav-scroll">
          <MetisMenu
            content={this.createContent()}
            iconNamePrefix="mdi mdi-"
            LinkComponent={CustomLink}
            activeLinkFromLocation
          />
        </div>
      </div>
    );
  }
}

export default Sidebar;
