import axios from 'axios'
import React, { Component } from 'react'
import Spinner from 'react-bootstrap/Spinner'

class ResultVote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            results: [],
            max: 0,
        }
    }

    componentDidMount() {
        if (this.props.voteId) {
            const url = `/api/v1/${this.getEventSlug()}/vote/${this.props.voteId}/results`

            axios.get(url, {headers: {
                'x-access-token': localStorage.getItem("token")
            }}).then((res) => {
                const results = res?.data ?? []
                let max = 0

                if (results.length > 0) {
                    max = results[0].count
                }
                
                this.setState({
                    results, 
                    loading: false,
                    max
                });
            }).catch( (error) => {
                console.log(error)
                if (error?.response?.status == 401) {
                    localStorage.removeItem("token");
                    window.open('/', "_self");
                }
            });
        }
    }

    getEventSlug() {
        return localStorage.getItem("bigjogos.adm.event.slug")
    }

    renderNotSaved() {
        return (
            <div className="form-row">
                <div className="form-group col-12">
                    <div className="alert alert-warning">
                        Salve a votação primeiro para ter acesso aos resultados!
                    </div>
                </div>
            </div>
        )
    }

    renderResults() {
        return this.state.results.map((result, index) => {
            return (
                <div className='row' key={`result_${index}`}>
                    <div className='col-2'>
                        <span>{result.name}</span>
                    </div>
                    <div className='col-8'>
                        <div className='progress'>
                            <div className='progress-bar' role='progressbar' style={{width: `${result.count / this.state.max * 100}%`}} aria-valuenow={result.count} aria-valuemin='0' aria-valuemax={this.state.max}>
                                {result.count}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        if (!this.props.voteId) {
            return this.renderNotSaved();
        }

        return (
            <div>
                <h2 className='mb-3'>Resultados</h2>

                {this.state.loading && (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                )}

                {!this.state.loading && (
                    <>
                        {this.renderResults()}

                        <div className='row mt-3'>
                            <div className='col-12'>
                                <p>Os itens que não aparecerem não receberam votos</p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

export default ResultVote