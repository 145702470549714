import React, { Component } from 'react'
import CommandItemEdit from './CommandItemEdit'

class CommandsEdit extends Component {


    addCommand = () => {
        let actions = this.props.value;

        if (!Array.isArray(actions))
        actions = [];

        actions.push({ target: "_self" });

        this.onCommandChangeValue(actions);
    }

    removeCommand = (index) => {
        let actions = this.props.value;
        actions.splice(index, 1);
        this.onCommandChangeValue(actions);
    }

    onChangeField = (e, index) => {
        let value = "";
        let actions = this.props.value;

        if (e.target.type === "checkbox")
            value = e.target.checked;
        else
            value = e.target.value;

        let action = actions[index];
        action[e.target.name] = value;
        actions[index] = action;

        this.onCommandChangeValue(actions);
    }

    onCommandChangeValue(pins) {
        if (this.props.onChange != undefined) {
            let event = {
                target: {
                    type: "commandEdit",
                    name: this.props.name,
                    value: pins
                }
            };

            this.props.onChange(event);
        }
    }

    renderCommands() {
        return this.props.value.map((item, index) => {
            let last = "";

            if (index >= (this.props.value.length - 1))
                last = (<button type="button" className="btn btn-outline-success btn-xs" onClick={this.addCommand}>adicionar</button>);

            return (
                <div key={(`command_${this.props.locale}_${index}`)} className="line-pin">
                    <div className="form-row">
                        <CommandItemEdit index={index} item={item} onChange={this.onChangeField} />
                        <div className="form-group buttons col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                            <button type="button" className="btn btn-outline-primary btn-xs" onClick={() => {this.removeCommand(index)}} >remover</button>
                            {last}
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderEmpty() {
        return (
            <div className="line-pin">
                <button type="button" className="btn btn-outline-success btn-xs" onClick={this.addCommand}>Adicionar Novo Comando</button>
            </div>
        )
    }

    render() {
        if (!Array.isArray(this.props.value) || this.props.value.length <= 0)
            return this.renderEmpty();

        return this.renderCommands();
    }
}

export default CommandsEdit;