import moment from 'moment'
import React, { Component } from 'react'

class GeneratePeriodsEdit extends Component {

    constructor(props) {
        super(props)

        this.state = {
            current: {
                type_generate: "0"
            },
            msg: ""
        }
    }

    onChangeField = (e, index) => {
        let value = ""

        if (e.target.type === "checkbox")
            value = e.target.checked
        else
            value = e.target.value

        let { current } = this.state
        current[e.target.name] = value
        this.setState({current})
    }

    generate = (e) => {
        e.preventDefault()
        if (!this.state.current.startDate
            || !this.state.current.endDate
            || !this.state.current.startTime
            || !this.state.current.endTime
            || !this.state.current.coupons
            || !this.state.current.qtdDays) {
            this.setState({msg: "Todos os campos são obrigatórios"})
        }
        else {
            let { type_generate, startDate, endDate, startTime, endTime, coupons, qtdDays } = this.state.current
            
            let currentDate = moment(startDate)
            endDate = moment(endDate)
            let periods = []

            while(currentDate.isSameOrBefore(endDate)) {
                let startDateTime = moment(`${currentDate.format("yyyy-MM-DD")}T${startTime}:00`)
                let endDateTime = moment(`${currentDate.format("yyyy-MM-DD")}T${endTime}:00`)
                let diff = endDateTime - startDateTime
                diff = diff / (qtdDays - 1)

                for (let i = 0; i < qtdDays; i++) {
                    let period = {
                        start: moment(startDateTime).format("yyyy-MM-DDTHH:mm")
                    }

                    startDateTime = moment(startDateTime + diff)
                    period.end = startDateTime.clone().subtract(1, 'minutes').format("yyyy-MM-DDTHH:mm")
                    periods.push(period)
                }

                currentDate = currentDate.add(1, 'days')
            }

            let periodCoupons = coupons / periods.length

            for (let period of periods) {
                period.limit = parseInt(periodCoupons)
            }

            if (this.props.onChangePeriod) {
                this.props.onChangePeriod(parseInt(type_generate), periods)
            }

            /*this.setState({
                current: {
                    type_generate: "0"
                },
                msg: ""
            })*/
        }
    }

    render() {
        if (this.props.show) {
            return (
                <div className={(`modalBackground open`)}>
                    <div className="modalDialog">
                        <div className="content-title">
                            <h4 className="card-title">Gerar Períodos</h4>
                        </div>
                        <div className="basic-form">
                            <form className="form-custom">
                                <div className="form-group row">
                                    <div className="col-6">
                                        <input type="radio" id="radioNew" name="type_generate" value="0" checked={this.state.current.type_generate == "0"} onChange={this.onChangeField} />
                                        <label htmlFor="radioNew"> Novos Períodos</label>
                                    </div>
                                    <div className="col-6">
                                        <input type="radio" id="radioReplace" name="type_generate" value="1" checked={this.state.current.type_generate == "1"} onChange={this.onChangeField} />
                                        <label htmlFor="radioReplace"> Substituir Períodos</label>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-6">
                                        <label>Início do Período</label>
                                        <input type="date" name="startDate" className="form-control form-control-sm" placeholder="Início do período" onChange={this.onChangeField} />
                                    </div>
                                    <div className="col-6">
                                        <label>Fim do Período</label>
                                        <input type="date" name="endDate" className="form-control form-control-sm" placeholder="Fim do período" onChange={this.onChangeField} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-6">
                                        <label>Início Horário</label>
                                        <input type="time" name="startTime" className="form-control form-control-sm" placeholder="Início do período" onChange={this.onChangeField} />
                                    </div>
                                    <div className="col-6">
                                        <label>Fim Horário</label>
                                        <input type="time" name="endTime" className="form-control form-control-sm" placeholder="Fim do período" onChange={this.onChangeField} />
                                    </div>
                                </div>                          
                                <div className="form-group row">
                                    <div className="col-6">
                                        <label>Quantidade</label>
                                        <input type="number" min="0" name="coupons" className="form-control form-control-sm" placeholder="Quantidade total de cupons" onChange={this.onChangeField} />
                                    </div>
                                    <div className="col-6">
                                        <label>Períodos por dia</label>
                                        <input type="number" min="0" name="qtdDays" className="form-control form-control-sm" placeholder="Quantidade de perídos por dia" onChange={this.onChangeField} />
                                    </div>
                                </div>
                                <div className="form-group col-12">
                                    <p>{this.state.msg}</p>
                                </div>
                                <div className="form-group col-12 modalBotoes">
                                    <button className="btn btn-dark" onClick={this.generate}>Gerar Periódos</button>
                                    <button className="btn btn-dark" onClick={this.props.toggleEvent}>Cancelar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }

        return ""
    }
}

export default GeneratePeriodsEdit
